import { Skeleton, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { columns, rows } from "./contactDialog/ContactReached";
import GridTable from "./grid_src";
import { ReactComponent as Elipse } from './Ellipse29.svg';
import { ReactComponent as ElipseInactive } from "./Ellipse29_inactive.svg";
import { DownloadPopup } from '@bamboobox/b2commoncomponents';
import { Link } from "react-router-dom"
// import { useQuery } from "./ParentScreen";
import TableEffect from "./skeletonEffectTable/tableEffect";
import { addCommatoNumber } from "./helper";
// import { async } from "q";
var icp = '';
const changeCampaignRow = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
    status
  }) =>
  { 
    // console.log("hellooeoo+",tableManager,column,value,);
    let widht = column.width.split("px");
    widht = widht[0]-80
    return (
      <div className="imgblock">
        {data.parentprogramstatus == "Active" ? (
          <Tooltip title={"Active"} leaveDelay={0} followCursor>
            <Elipse />
          </Tooltip>
        ) : (
          <Tooltip title={"Inactive"} leaveDelay={0} followCursor>
            <ElipseInactive />
          </Tooltip>
        )}
        {data.source && (
          <img
            width="20"
            height="20"
            style={{ borderRadius: "12px" }}
            src={`${
              window._env_.REACT_APP_INTENTSETTING
            }/logos/${data.source.toLowerCase()}.png`}
            alt="logo"
          ></img>
        )}
        <a
          style={{ textDecoration: "underline", color: "#165154" }}
          href={`#/?parentcampaignId=${data?.parentid ?? ""}&icp=${
            icp ?? ""
          }&parentCampaignName=${value ?? ""}&source=${
            data.parentprogramstatus
          }`}
        >
          <Tooltip title={value} enterDelay={500} leaveDelay={0} followCursor>
            <Typography
              style={{
                whiteSpace: "nowrap",
                maxWidth: `${widht}px`,
                cursor: "pointer",
                color: "#165154",
              }}
              noWrap
            >
              {value}
            </Typography>
          </Tooltip>
        </a>
        ({data.totatchildpresent})
      </div>
    );
  }
  const checkOtherRow = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) =>
  (
  
    <div style={{display:"flex",alignItems:"baseline"}} >
      {/* {(column.field == 'emaildelivered')? (mapNotPresent.includes(data.source?.toLowerCase()??'') ?  value : data.emaildeliveredothermap ) : value} */}
      {column.type==='date'?
      value.slice(0,10)
      :
      addCommatoNumber(value)
      }
       &nbsp; 
       <span className= {parseInt(column.perValue) <= parseInt(data[column.field+'per']) ? "percentage" : "percentage-condition"} >{data[column.field+'per'] && 
       data[column.field+'per']+"%"} 
       </span>

  </div>
    )
    
export const ParentGridTable=(props)=>{
  icp = props.icp;
  const [rowData,setRowData] = useState([]);
  // const [tenantMapperData,setTenantMapperData] = useState();
  const [loading,setLoading] = useState(true);
  const [columnData,setColumnData] = useState([]);
  

  const visiblerow = (e) =>{
    let visiblearr = []
    console.log("e-->",e);
    for(let visiblity of e){
      if(visiblity.visible !== undefined){
        visiblearr.push(visiblity.label)
      }
    }
    console.log("visiblearr--->",visiblearr);
    props.setdownloadattr(visiblearr);
    props.setdownloadclick(true);    
  }

  const getCount = ()=>{
    let diff = props.parentData?.rowdata?.totalCount - rowData.length
    console.log("::diff",diff,rowData.length,props.parentData?.rowdata?.totalCount)
    if(diff>150){
      console.log("::diff inside if",diff,rowData.length,props.parentData?.rowdata?.totalCount)
      return 150;
    }
    else{
      console.log("::diff inside else",diff,rowData.length,props.parentData?.rowdata?.totalCount)
      return diff;
    }
  }
  const fetchData = async()=>{
    try {
      
      let lastElement = rowData.slice(-1);
      if(lastElement[0] == null ) return;
      console.log("rowData.length",rowData.length,props.parentData?.rowdata?.totalCount,props.parentData?.rowdata?.totalCount);
      if(props.parentData?.rowdata?.totalCount===undefined || rowData.length>=props.parentData?.rowdata?.totalCount  ) return;
        
      // let array = [...rowData,...Array(getCount()).fill(null)];
        setRowData(prev=> [...prev,...Array(getCount()).fill(null)]);
        props.setCampResponse([])
        props.setPage(option=>option+1);

    } catch (error) {
        console.error("error",error);
    }
}
  function sentenecCase(key,sub) {
    try {
      
      const finalResult = props.tenantMapperData[key][sub];
  
      return finalResult;
    } catch (error) {
      return undefined;
    }
  }
      
    const parentGridTablecolumnandrow = ()=>{
        try {
          
            if(('totalcampaigndata' in props.parentData)){
              console.log("after filter",props.parentData?.totalcampaigndata);
              console.log("parent Status --",props.status);
                if(props.parentData?.totalcampaigndata && props.parentData?.totalcampaigndata.length ){
                    const campaignKeys = Object.keys(props.parentData.totalcampaigndata[0]);
                    let campaignColumn = [];
                    campaignKeys.forEach((key,index)=>{
                       const obj = {
                         id: index+1,
                         field: key,
                         label: sentenecCase(key,"label"),
                         order: sentenecCase(key,"order"),
                         visible:sentenecCase(key,"visible"),
                         type: sentenecCase(key,"type"),
                         value:props?.parentData?.rowdata["total"+key]??null,
                         perValue:props?.parentData?.rowdata["total"+key+"per"]??null,
                         status:props?.status??'Active'
                        //  columnVisibility: checkAHColumnVisibility(key)
                       };
                        if (key == "parentprogramname"){
                          obj.cellRenderer = changeCampaignRow;
                          obj.value = `Total ${addCommatoNumber(props.parentData?.rowdata?.totalCount) ?? 0}`
                          obj.pinned = true
                          obj.width ='300px'

                          obj.visible = true
                       }
                      //  else if(key == 'contactsreached'){
                      //   obj.cellRenderer = contactReachedOpen;
                      //  }
                      //  else if(key === 'accountsreached'){
                      //   obj.value = props.parentData.totalaccountsreached;
                      //  }
                       else {
                        obj.cellRenderer = checkOtherRow;
                       }
                       campaignColumn.push(obj);
                     }) 
                     campaignColumn  = campaignColumn.filter(e=>e?.label).sort((a,b)=>{
                      return a.order  - b.order
                     });
              
                    //  console.log("campaigns column:",campaignColumn,props?.campaignData);
                     setColumnData(campaignColumn);
                    //  const rowData = 
                    // const lenght = props.parentData.totalcampaigndata.lenght;
                    // let findIndex = setRowData.findIndex(e=>e == undefined);
                    // console.log("result==>",rowData,rowData.splice(0,2,...props.parentData.totalcampaigndata))
                    // setRowData(props.parentData.totalcampaigndata);
                    const lenght = props.parentData.totalcampaigndata.length;
                    const index = 150*(props.page-1);
                    console.log("result==>",lenght,index,rowData.length,props.page)
                    if(props.page == 1){
                      setRowData(props.parentData.totalcampaigndata);
                    }
                    else{
                      // setRowData([...rowData,...props.parentData.totalcampaigndata]);
                     rowData.splice(index,lenght,...props.parentData.totalcampaigndata);
                    //  setRowData(arr=>arr.splice(index,lenght,...props.parentData.totalcampaigndata));
                      // setRowData(prev=> {
                      //     console.log("prevdata",prev,props.parentData.totalcampaigndata);
                      //     return prev.splice(index,lenght,...props.parentData.totalcampaigndata)
                      //     // return [...prev,...props.parentData.totalcampaigndata]
                      //   }
                      //   )
                    }
                  }
                  else if(props.page==1){
                    setRowData([]);
                    setColumnData(prev =>prev.map(element => {
                      if(element.field !== 'parentprogramname') 
                      {element.value = 0;
                        element.perValue = 0;
                      }
                      else{
                        element.value ='Total'
                      }
                      return element;
                    })
                    )
                  }
                  setLoading(false)
       }
     } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        setLoading(true)
    },[props.sort,props.sortby])
    useEffect(()=>{parentGridTablecolumnandrow()}, [props.parentData])
    console.log("parentGrid screen",props);

    return(
        <>
        {!(loading||props.isLoading)?   (<GridTable fetchData={fetchData}
     page={props.page}
     setPage={props.setPage}
     isLoading={loading}
     isnext={props.isnext}
     columns={columnData}
     showSearch={false}
     count={props.parentData?.rowdata?.totalCount ?? 0}
     rows={rowData}
     setRows={setRowData}
    //  isHeaderSticky={true}
     isPaginated={false}
    //  onColumnsChange={onColumnChangeSave(setColumnData)}
     sort={props.sort}
     onSortChange={({ colId, isAsc }) => {

      props.setPage(1);
      console.log('in sort change func', colId, isAsc)
      // setRowsData([]);
      // setLoading(true);
      const sortArr = columnData.filter((e) => e?.id == colId);
      console.log('sortArr is ', sortArr);
      const sortOrderChange = {};
      if (colId) {
        sortOrderChange.sortBy = sortArr[0]?.field;
        sortOrderChange.sortCol = parseInt(colId);
      } else {
        sortOrderChange.sortBy = "";
        sortOrderChange.sortCol = null;
      }
      ///////////for sorting numerical fields by desc first///////////////
      //////////////imp read this/////////////
      //if sorting numerical columns change also headercellcontainer.js file
      ////////////////////////////////////////

        if (isAsc) {
          sortOrderChange.sortType = "asc";
          sortOrderChange.sortColBool = true;


        } else {
          sortOrderChange.sortType = "desc";
          sortOrderChange.sortColBool = false;
        }
        
      // console.log('sortOrderChange ', sortOrderChange)
      // console.log('sort ', sort)
      // console.log('sortby ', sortby)
      // console.log('sortType ', sortType)


      props.setColumnSortDetails(sortOrderChange, props.setSort, props.setSortby, props.setSortType)  }}
      />):
        (<TableEffect/>)
        }
        <DownloadPopup object={"Campaign"} columns={columnData}  count={""} open={props.open} setColumn={props.downloadButtonAction} setOpen={props.setOpen} getDownlaod={visiblerow}/> 
        </>
    )
} 
import axios from "axios";
export async function getSelectedSegments (tenantId, userId,icp,type) {
    console.log("t+u",tenantId,userId,window._env_.REACT_APP_USER_BACKEND);
    const selectedSegments = await axios.get(`${window._env_.REACT_APP_USER_BACKEND}/users/get-last-used-segments/${tenantId}/${userId}/${icp}/${type}`, 
                             { 
                               withCredentials: true 
                             }).then(res => res.data)
    // console.log("userDataaaaa",selectedSegments);                         
    return selectedSegments
  }
  
  export async function updateSelectedSegments (tenantId, userId,icp, segments) {
    console.log("segVal",segments);
    let type=segments[0]?.segmentType;
    let value=segments[0]?.filterName;
    if(value==='All Campaigns'){
      value='All Accounts';
      type='account';
    }
    await axios.post(`${window._env_.REACT_APP_USER_BACKEND}/users/update-segments/${tenantId}/${userId}/${icp}/${type}`, 
      {
          segmentArray: value,
      },
      { 
        withCredentials: true 
      }).then(res => res)
  }
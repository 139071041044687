import React from 'react';
import { addCommatoNumber } from '../../helper';

const HeaderCell = ({ column, tableManager }) => {
    // console.log("column values:",column);
    const { config: { additionalProps: { headerCell: additionalProps = {} } } } = tableManager;

    let classNames = ('rgt-text-truncate-column ' + (additionalProps.className || '')).trim();

    return (
        <div style={{display:"flex",flexDirection:"column"}}>
        <span 
            {...additionalProps} 
            // style={{padding:"6px 24px 6px 16px"}}
            style={{width:"250px"}}
            className={classNames} 
            data-column-id={column.id.toString()}
        >{column.label}
        </span>
        { 'value' in column &&
        <span 
        {...additionalProps} 
        className={ " rgt-text-sub-head"} 
        data-column-id={column.id.toString()}>{addCommatoNumber(column.value)} &nbsp;  <span className="percentage"> {column.perValue && column.perValue+"%"} </span>
        </span>
    }
    </div>
    )
};

export default HeaderCell;
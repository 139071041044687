import { CircularProgress } from '@mui/material';
import './App.css';
import {lazy,Suspense} from "react";
// import FunnelReportPage from "./FunnelReportPage";
import {
  HashRouter,
  Route,
  Routes
} from 'react-router-dom';
import { ParentScreen } from './ParentScreen';

const App = lazy(async() =>{ 
  let obj = await import("./App")
  return typeof obj.default === 'function' ? obj : obj.default
})
const GoogleAdsKeywords = lazy(async() =>{ 
  let obj = await import('./googleads/googleThirdScreen')
  return typeof obj.default === 'function' ? obj : obj.default
})
// import CacheBuster from 'react-cache-buster';
// import  packageInfo  from '../package.json';

// const version = packageInfo.version
// console.log("verison of build", version);
function RouterApp() {
  return (
    <div>
       <HashRouter>
            <Routes>

                <Route exact path="/" element={
                  <Suspense fallback={<div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
                  <CircularProgress style={{color:"#21DA8C"}}/>
                  </div>} >
                <App/>
                </Suspense>
                } ></Route>
                <Route exact path='/parentcamapaign' element={
               
                <ParentScreen/>
             
                }></Route>
                <Route exact path='/googleads' element={
                  <Suspense fallback={
                  <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
                  <CircularProgress style={{color:"#21DA8C"}}/>
                  </div>
                  }> <GoogleAdsKeywords/> </Suspense>
                }></Route>
                {/* <Route exact path="/filter/:id" component={FunnelReportPage} ></Route> */}
            </Routes>
        </HashRouter>
    </div>
  );
}

export default RouterApp;
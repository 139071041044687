import GridTable from "../grid_src"
import { Link, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
// import data from "../data.json";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  useColumnVisibilityPersist,
} from "@bamboobox/b2gridutils";
import { ContactReached } from "../contactDialog/ContactReached";
import TableEffect from "../skeletonEffectTable/tableEffect";
import { addCommatoNumber } from "../helper";
// var dawhateverta = new Array(200);
// dawhateverta = [...data,...dawhateverta]
// const mapNotPresent = process.env?.REACT_APP_SOURCE?.split(',');
// console.log("mapNotPresent",mapNotPresent);
const changeCampaignRow = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) =>
  { 
    // console.log("hellooeoo+",tableManager,column,value,);
    let widht = column.width.split("px");
    widht = widht[0]-40
    return(
  
    
    <div className='imgblock'>
    { data.source && <img
        width="20"
        height="20"
        style={{borderRadius:"12px"}}
        src={`${window._env_.REACT_APP_INTENTSETTING}/logos/${(data.source).toLowerCase()}.png`}
        alt="logo"
      ></img>
    }
          <Link href={data.link&&data.link} rel="noopener" target="_blank" underline="none">

      <Tooltip title={value} enterDelay={500} leaveDelay={0} followCursor>
      
      <Typography style={{whiteSpace: "nowrap",maxWidth:`${widht}px`,cursor:"pointer",textDecoration: "underline",color:"#165154"}} noWrap>
      {value} 
      </Typography>
      </Tooltip>
      </Link>
      {/* {data.programStatus == "Active"&& <Elipse/>} */}
  </div>
    )
  }
  const checkOtherRow = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) =>
  (
  
    <div style={{display:"flex",alignItems:"baseline"}} >
      {/* {(column.field == 'emaildelivered')? (mapNotPresent.includes(data.source?.toLowerCase()??'') ?  value : data.emaildeliveredothermap ) : value} */}
      {column.type==='date'?
      value.slice(0,10)
      :
       addCommatoNumber(value)
      }
       &nbsp; <span className= {parseInt(column.perValue) <= parseInt(data[column.field+'per']) ? "percentage" : "percentage-condition"} >{data[column.field+'per'] && 
       data[column.field+'per']+"%"} </span>
  </div>
    )


  export const GridView=(params)=>{
  function sentenecCase(key,sub) {
    try {
      
      const finalResult = tenantMapperData[key][sub];
  
      return finalResult;
    } catch (error) {
      return undefined;
    }
  }
  // const [getColumnSortDetails, setColumnSortDetails] =
  // useColumnSortOrderPersist("B2CampaignHViewListing" );
  // const [sortby, setSortby] = useState(getColumnSortDetails("sortBy"));
  const [columVisibilityTransformer, onColumnChangeSave] =
    useColumnVisibilityPersist("B2CampaignViewListing");
  // const columnData = useState(columns);
  const [rowData,setRowData] = useState([]);
  const [tenantMapperData,setTenantMapperData] = useState();
  const [loading,setLoading] = useState(true);
  const [contactMapper,setContactMapper] = useState([]);
  const [contactOpen,setContactOpen] = useState(false);
  const [campaign,setCampaign] = useState({});
  useEffect(()=>{
    setLoading(true)
  },[params.sortby,params.sortType,params.loading])
  // let [sort, setSort] = useState({ colId: getColumnSortDetails("sortColumnNo"), isAsc: getColumnSortDetails("sortOrderBool") });
  // const [sortType, setSortType] = useState(getColumnSortDetails("sortType"));
  const [columnData,setColumnData] = useState(columVisibilityTransformer([]));
  const getCount = ()=>{
    let diff = params.campaignData?.totalCount - rowData.length
    console.log("::diff",diff,rowData.length,params.campaignData?.totalCount)
    if(diff>150){
      console.log("::diff inside if",diff,rowData.length,params.campaignData?.totalCount)
      return 150;
    }
    else{
      console.log("::diff inside else",diff,rowData.length,params.campaignData?.totalCount)
      return diff;
    }
  }
  const fetchData = ()=>{
    try {
        // console.log("calling....112",params.page,rowData)
        // params.rows.push(...[new Array(250)])
        

        let lastElement = rowData.slice(-1);
        if(lastElement[0] == null ) return;
        console.log("rowData.length",rowData.length,params.campaignData?.totalrowsum?.totalrowCount);
        if(params.campaignData?.totalrowsum?.totalrowCount===undefined || rowData.length>=params.campaignData?.totalrowsum?.totalrowCount  ) return;
        let array = [...rowData,...Array(getCount()).fill(null)];
        
        setRowData(array);
        params.setCampResponse([])
        params.setPage(params.page+1);

    } catch (error) {
        console.error("hello");
    }
}

const handleContactDialog=(name,id)=>{
  setContactOpen(!contactOpen);
  setCampaign({name,id});


}
const contactReachedOpen = ({tableManager,value,data,column,colIndex,rowIndex,}) =>(
  <span className="contactReachButton" onClick={()=>handleContactDialog(data.campaignname,data.campaignId)} >
    {addCommatoNumber(value)}
  </span>)

  const getTenantMapper = async()=>{
    try {
      ;
      const tenantResponse = await axios.get(`${window._env_.REACT_APP_TENANT_CONFIG}/tenant/field/mapper/get/${params.tenantId}/CampaignHub-creatio`,{ withCredentials: true });
      // setResponse(tenantResponse.data);
      setTenantMapperData(tenantResponse.data);
      
    } catch (error) {
      console.error(error)
      setTenantMapperData({});
    }

  }
    const convertData = ()=>{
      try {
      if(params.campaignData?.totalcampaigndata && params.campaignData?.totalcampaigndata.length){
      const campaignKeys = Object.keys(params.campaignData.totalcampaigndata[0]);
      let campaignColumn = [];
      campaignKeys.map((key,index)=>{
         const obj = {
           id: index+1,
           field: key,
           label: sentenecCase(key,"label"),
           order: sentenecCase(key,"order"),
           type: sentenecCase(key,"type"),
           value:params.campaignData['totalrowsum']["totalrow"+key],
           perValue:params.campaignData['totalrowsum']["totalrow"+key+"per"]
           // columnVisibility: checkAHColumnVisibility(key)
         };
          if (key == "campaignname"){
            obj.cellRenderer = changeCampaignRow;
            obj.value = `Total ${addCommatoNumber(params.campaignData?.totalrowsum?.totalrowCount) ?? 0}`
            obj.pinned = true
            obj.width ='300px'
            obj.visible = true
         }
         else if(key == 'contactreached'){
          obj.cellRenderer = contactReachedOpen;
         }
        //  else if(key === 'accountreached'){
        //   obj.value = params.campaignData.totalaccountsreached
        //  }
         else {
          obj.cellRenderer = checkOtherRow;
         }
         campaignColumn.push(obj);
       }) 
       campaignColumn  = campaignColumn.filter(e=>e?.label).sort((a,b)=>{
        return a.order  - b.order
       });

       console.log("campaigns column:",campaignColumn,params?.campaignData);
       setColumnData(campaignColumn);
      //  const rowData = 
      // const lenght = params.campaignData.totalcampaigndata.lenght;
      // let findIndex = setRowData.findIndex(e=>e == undefined);
      // console.log("result==>",rowData,rowData.splice(0,2,...params.campaignData.totalcampaigndata))

      const lenght = params.campaignData.totalcampaigndata.length;
      const index = 150*(params.page-1);
      if(params.page == 1){
        setRowData(params.campaignData.totalcampaigndata);
      }
      else{

        console.log("result==>",lenght,index,rowData.length)
        // setRowData([...rowData,...params.campaignData.totalcampaigndata]);
       rowData.splice(index,lenght,...params.campaignData.totalcampaigndata);
      }
       setLoading(false)
      }else{
        setRowData([]);
        columnData.forEach(element => {
          if(element.field !== 'campaignname') 
          {element.value = 0;
          element.perValue = 0;
        }
        else{
          element.value = 'Total';
        }
        });
        setLoading(false);
      }

      } catch (error) {
        console.log("error occurred in Convert data  function ",error);

      }
    }
    useEffect(() => {
      // if (tenantConfigMapper === undefined || tenantConfigMapper === null) {
      fetch(
        `${window._env_.REACT_APP_TENANT_CONFIG}/tenant/field/mapper/get/${params.tenantId}/Campaign-contact-screen`,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then((mapper) => {
          setContactMapper(mapper)
        })
        .catch((error) => {
          console.log("error", error);
        });
      // }
    }, []);
  
    useEffect(()=>{
      getTenantMapper()
    },[])
    useEffect(()=>{
      if(tenantMapperData !== undefined && 'totalcampaigndata' in params.campaignData) {
        ;
        convertData();
      }
    },[tenantMapperData,params.campaignData,params.page])
    return (
      <>
      { !loading ? (
        
        <Paper style={{ borderRadius: '18px', padding: `5px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF` ,zIndex:"100",position:"relative"  }}>
   <GridTable 
   fetchData={fetchData}
   page={params.page}
   setPage={params.setPage}
    isLoading={loading}
     columns={columnData}
     showSearch={false}
     count={params.campaignData?.totalrowsum?.totalrowCount ?? 0}
     rows={rowData}
     setRows={setRowData}
     isHeaderSticky={true}
     isPaginated={false}
     onColumnsChange={onColumnChangeSave(setColumnData)}
     sort={params.sort}
     isnext={params.isnext}
     onSortChange={({ colId, isAsc }) => {

      params.setPage(1);
      console.log('in sort change func', colId, isAsc)
      // setRowsData([]);
      // setLoading(true);
      const sortArr = columnData.filter((e) => e?.id == colId);
      console.log('sortArr is ', sortArr);
      const sortOrderChange = {};
      if (colId) {
        sortOrderChange.sortBy = sortArr[0]?.field;
        sortOrderChange.sortCol = parseInt(colId);
      } else {
        sortOrderChange.sortBy = "";
        sortOrderChange.sortCol = null;
      }
      ///////////for sorting numerical fields by desc first///////////////
      //////////////imp read this/////////////
      //if sorting numerical columns change also headercellcontainer.js file
      ////////////////////////////////////////

        if (isAsc) {
          sortOrderChange.sortType = "asc";
          sortOrderChange.sortColBool = true;


        } else {
          sortOrderChange.sortType = "desc";
          sortOrderChange.sortColBool = false;
        }
        
      // console.log('sortOrderChange ', sortOrderChange)
      // console.log('sort ', sort)
      // console.log('sortby ', sortby)
      // console.log('sortType ', sortType)


      params.setColumnSortDetails(sortOrderChange, params.setSort, params.setSortby, params.setSortType)
    }}
    // isVirtualScroll={true}
    />
        </Paper>
    )
    :    
    (<TableEffect/>)
    }
    <ContactReached open={contactOpen} campaign={campaign} handleContactDialog={handleContactDialog} tenantId={params.tenantId} contactMapper={contactMapper} icp={params.icp} />

    {/* <DownloadPopup open={true} columns={columnData} object={"Contact"} setColumn={setColumnData} setOpen={setColumnData}/> */}
  </>
    )
}
import DateFnsUtils from "@date-io/date-fns";
import styled from "@emotion/styled";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ChevronRight, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Button, ButtonGroup, FormControl, Link, ListSubheader, MenuItem, Select, TextField, Typography } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangePickerComponent from '../grid_src/components/DateRangePickerComponent'
import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import {updateSelectedSegments,getSelectedSegments} from "../api"
import '../grid_src/components/Header.css';

// import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const CustomButtonGroup = styled(ButtonGroup)(({ theme })=>({
  root: {
      borderRadius: "8px",
      // color:"#03030399!important"
  },
}));
const CustomButton = styled(Button)(({ theme })=>({
  root: {
      fontWeight:"700!important",
      fontSize:"13px",
      color:"#03030399",
  },
}));
export const Header=(props)=>{
  var threeWeeksOldDate = new Date();
  if(props.input=='1') threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 7) 
  else threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);

  let frequencyValues = ["Weeks", "Months", "Quarters", "Years"];
  function GetDateFrom (){
    let date = sessionStorage.getItem("oldDate") || threeWeeksOldDate 
    return new Date(date);
  }
  const [fromDate, setFromDate] = React.useState(new Date())
  const [toDate, setToDate] = React.useState(new Date()) 
  const [value, setValue] = useState('');
  const [oldDate, setOldDate] = useState(GetDateFrom());
  const [disable,setdisable] =  useState(false);
  
    const [fromDateMax, setFromDateMax] = useState(new Date());
    const [segValue,setSegValue] = useState([]);
    const [radioBtnValue, setRadioBtnValue] = useState(sessionStorage.getItem("radioBtnValue") || "fixed");
    const fetchSegData = async()=>{
      try {
        if(!segValue.length){
        const segmentResp = await axios.get(`${window._env_.REACT_APP_SEGMENTAPI}/filters/get-filter?tenantId=${props.tenantId}&ui=export&userUuid=${props.uuid}`,{withCredentials:true});
        
        segmentResp.data.data.push({"filterName":"All Campaigns"})
        console.log("segment res",segmentResp);
        setSegValue(segmentResp.data.data??[]);
        }
      } catch (error) {
        console.error("error in fetching Segment Data:",error);
        return '';
      }
    }
    useEffect(() => {
      fetchSegData()
      if(props.screen === "2"){
        setdisable(true);
      }
      console.log("props.hideHeader-->",props.hideHeader)
    }, [])
    useEffect(() => {
      /* disable on tabValue=2 >>> googleAds */
      if(props?.tabValue?.toString()){
        if(props.tabValue === 2) setdisable(true);
        else setdisable(false);
      }
    }, [props.tabValue])

    const setSegments=async()=>{
      let segmentData=await getSelectedSegments(props.tenantId,props.uuid,props.icp,'account');
      console.log("segmentData",segmentData);
      if(segmentData.success && segmentData.data){
        let segVal=segmentData.data;
        if(segVal==="All Accounts"){
          segVal="All Campaigns"
        }
        props.setSegment(segVal);
      }else{
        props.setSegment("All Campaigns");
      }
      
     }
  
     useEffect(()=>{
      setSegments();
     },[props.icp]) 

    const handleSegmentChange=async(event,data)=>{
      console.log("eventVal",event.target.value);
      props.setSegment(event.target.value);
      sessionStorage.setItem(`campaignName${props.tenantId}`,event.target.value);
      // let changedValue=event.target.value;
      let changedValue=segValue.filter((obj)=>obj.filterName===event.target.value);
      // if(changedValue==='All Campaigns'){

      // }
      const res=await updateSelectedSegments(props.tenantId, props.uuid,props.icp,changedValue);

      
    }
    
    
    return (
        <div className='headerTop'>
          <div className='headerRight'>
          <Link href={props.campaignName&&`#/parentCamapaign?icp=${props.icp}${props.tab ? "&tab=" + props.tab : ""}`} underline="none">
        <Typography variant='h5' fontFamily={'Nunito Sans'} fontWeight={600}>
        Campaigns 
        </Typography>
        </Link>
        {'action' in props &&
        <CustomButtonGroup size="small"    variant="contained" aria-label="outlined primary button group">
                <CustomButton size="small" sx={{fontWeight:"700!important",color:"rgba(3, 3, 3, 0.6)!important",fontFamily:"Nunito Sans"}} onClick={()=>props.handleClick('All')} style={props.action=='All'?{background:"rgba(211, 248, 232, 1)"}:{background:"rgba(255, 255, 255, 1)"}}  >All</CustomButton>
                <CustomButton size="small" sx={{fontWeight:"700!important",color:"rgba(3, 3, 3, 0.6)!important",fontFamily:"Nunito Sans"}} onClick={()=>props.handleClick('Active')} style={props.action=='Active'?{background:"rgba(211, 248, 232, 1)"}:{background:"rgba(255, 255, 255, 1)"}} >Active</CustomButton>
                <CustomButton size="small" sx={{fontWeight:"700!important",color:"rgba(3, 3, 3, 0.6)!important",fontFamily:"Nunito Sans"}} onClick={()=>props.handleClick('Inactive')} style={props.action=='Inactive'?{background:"rgba(211, 248, 232, 1)"}:{background:"rgba(255, 255, 255, 1)"}} >Inactive</CustomButton>
                </CustomButtonGroup>
        }
        
        { props.campaignName &&
        <>
            <ChevronRight/>
            {
              /* add icon if header for google-ads */
              (props?.tab === 'googleads' || props?.tab === 'googlegroup') && 
              (<img
                width="22"
                height="22"
                src={`${
                  window._env_.REACT_APP_INTENTSETTING
                }/logos/${"googleads"}.png`}
                alt="logo"
              ></img>)
            }
            {props?.tab === 'googlegroup' ? (
              <Link href={`#/?tab=googleads&icp=${props?.icp}&parentcampaignId=${props?.campaignId}&parentCampaignName=${props?.campaignName}&source=${props?.GetParentStatus()}`} underline="none">
                <Typography variant='h5' fontWeight={600} >
                  {props.campaignName}
                </Typography>
              </Link>
            ) : (
              <Typography variant='h5' fontWeight={600} >
                {props.campaignName}
              </Typography>
            )
            }
            {props.GetParentStatus() == "Active" && 
              (<div className="roundShape">
                <Typography style={{"text-decoration": "none"}} fontSize={"12px"} fontWeight={800} className='active' >
                ACTIVE
                </Typography>
              </div>)
            }
            {
              /* add sub header if header for google-ads-group */
              props?.tab === 'googlegroup' && 
              (
                <div style={{display: 'flex', alignItems: 'center', order: 1}}>
                  <ChevronRight/>
                  <Typography variant='h5' fontWeight={600} >
                    {props.parentGroupName}
                  </Typography>
                </div>
              )
            }
        </>
        }
       
        </div>
        
        <span className="headeraction">
         {/* <FormControl
          // disabled={disable}
                            size="small"
                            className="demo"
                           
                        > 
                        <Select 
                        style = {{zIndex: "9", cursor:"pointer" , height:"34px", fontFamily:"Nunito Sans",margin:"8px 0px"}}
                         id="toggle-select"
                        value={props.toggle}
                        variant="outlined"
                        IconComponent={KeyboardArrowDownRounded}
                        //  defaultValue="All Accounts"
                        //  placeholder="All Accounts"
                        inputProps={{
                            // classes: {
                            //     icon: classes.vector,
                            //     value: classes.grp29text,
                            // },
                        }}
                        MenuProps={
                            {
                             PaperProps: {
                                 style: {
                                   borderRadius: "16px",
                                   fontFamily:"Nunito Sans"
                                 }
                               },
                              getContentAnchorEl: () => null,
                              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                               transformOrigin: { vertical: 'top', horizontal: 'left' },
     
                                }
                             }
                        onChange={(event, data) => {
                          props.handleToggle(event.target.value);
                          // props.setSegment(event.target.value);
                          // sessionStorage.setItem(`toggle${props.tenantId}`,event.target.value);
                        // props.setSegType();
                        }}
                        // renderValue={(value) => <label className={''}>{value}</label>}
                    >
                      <MenuItem id={props.toggle=='marketing sourced'&&"selected"} style={{ display: "flex",
                justifyContent: "left",
                background: "#fff",
                padding: "6px 16px",
                color:"rgba(0, 0, 0, 0.87)",
                letterSpacing: "0.15px",
                fontSize: "1rem",
                fontWeight:"500",
                }}

                value="Marketing Sourced" >
            marketing Sourced
          </MenuItem>
          <MenuItem id={props.toggle=='marketing influenced'&&"selected"} style={{ display: "flex",
                justifyContent: "left",
                background: "#fff",
                padding: "6px 16px",
                color:"rgba(0, 0, 0, 0.87)",
                letterSpacing: "0.15px",
                fontSize: "1rem",
                fontWeight:"500",
                }}

                value="marketing influenced" >
Marketing Influenced          </MenuItem>
                        </Select>
        </FormControl> */}




          {props.hideHeader === true | props.hideHeader === "true"?  
        <FormControl
          disabled={disable}
                            size="small"
                            className="demo"
                           
                        > 
                        <Select 
                        style = {{zIndex: "9", cursor:"pointer" , height:"34px", fontFamily:"Nunito Sans",margin:"8px 0px"}}
                         id="accountsegment-select"
                        value={props.segment}
                        variant="outlined"
                        IconComponent={KeyboardArrowDownRounded}
                        //  defaultValue="All Accounts"
                        //  placeholder="All Accounts"
                        inputProps={{
                            // classes: {
                            //     icon: classes.vector,
                            //     value: classes.grp29text,
                            // },
                        }}
                        MenuProps={
                            {
                             PaperProps: {
                                 style: {
                                   borderRadius: "16px",
                                   fontFamily:"Nunito Sans"
                                 }
                               },
                              getContentAnchorEl: () => null,
                              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                               transformOrigin: { vertical: 'top', horizontal: 'left' },
     
                                }
                             }
                        onChange={(event, data) => {
                          // props.setSegment(event.target.value);
                          // sessionStorage.setItem(`campaignName${props.tenantId}`,event.target.value);
                        // props.setSegType();
                        handleSegmentChange(event, data);
                        }}
                        renderValue={(value) => <label className={''}>{value}</label>}
                    >
          {segValue.filter((obj)=>!obj?.segmentType).map((e)=>(
            <MenuItem id={props.segment==e.filterName&&"selected"} style={{ display: "flex",
                justifyContent: "left",
                background: "#fff",
                padding: "6px 16px",
                color:"rgba(0, 0, 0, 0.87)",
                letterSpacing: "0.15px",
                fontSize: "1rem",
                fontWeight:"500",
                }}
                value={e.filterName}>
            {e.filterName}
          </MenuItem>
          ))}
                    {segValue.some(e=>e.segmentType=='account') && <ListSubheader className="MuiListSubheader" >ACCOUNT SEGMENTS </ListSubheader>}
                        {(segValue.filter(e=>e.segmentType=='account')).map((data) => (
                        <MenuItem id={props.segment==data.filterName&&"selected"} style={{ display: "flex",
                        justifyContent: "left",
                        background: "#fff",
                        padding: "6px 16px",
                        color:"rgba(0, 0, 0, 0.87)",
                        letterSpacing: "0.15px",
                        fontSize: "1rem",
                        fontWeight:"500",
                        }} key={data.filterName} name={data.segmentType} value={data.filterName} onClick={()=>{props.setSegType(data.segmentType);sessionStorage.setItem(`type${props.tenantId}`,data.segmentType)}} classes={ '' }>{data.filterName}</MenuItem>
                        ))} 
                      {props.tabValue===0 && segValue.some(e=>e.segmentType=='contact') && <ListSubheader className="MuiListSubheader" >CONTACT SEGMENTS</ListSubheader>}
                      {props.tabValue===0 && (segValue).filter(e=>e.segmentType=='contact').map((data) => (
                          <MenuItem style={{ display: "flex",
                          justifyContent: "left",
                          background: "#fff",
                          padding: "6px 16px",
                          color:"rgba(0, 0, 0, 0.87)",
                          letterSpacing: "0.15px",
                          fontSize: "1rem",
                          fontWeight:"500",
                          }} id={props.segment==data.filterName&&"selected"} key={data.filterName} name={data.segmentType} value={data.filterName} onClick={()=>{props.setSegType(data.segmentType);sessionStorage.setItem(`type${props.tenantId}`,data.segmentType);}} classes={ '' }>{data.filterName}</MenuItem>
                          ))} 
                        </Select>
        </FormControl> : ""}
                                                          
         {props.hideHeader === true | props.hideHeader === "true"?              
        <DateRangePickerComponent toDate={props.enddate} setToDate={props.setenddate} fromDate={props.startdate} setFromDate={props.setstartdate} screen = {props.screen} tenantId = {props.tenantId} style={{ width: "18vw", maxWidth: "18vw",  }}/>    
           : ""}
        </span>
      </div>
      
    )
}
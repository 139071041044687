export const addCurrencySuffix = (value) => {
    try {
        let stringValue = value.toString();
        if (stringValue.lastIndexOf('%') >= 0) {
            return value;
        }
        let number = parseInt(value)
        if (isNaN(number)) {
            throw value + " is not number";
        }
        let suffixes = ["", "K", "M", "B", "T"];
        let suffixIndex = 0;

        while (number >= 1000 && suffixIndex < suffixes.length - 1) {
            number /= 1000;
            suffixIndex++;
        }

        return suffixIndex ? number.toFixed(2) + suffixes[suffixIndex] : number;
    } catch (error) {
        console.error("value is not number:", error);
        return value;
    }
}
export const addCommatoNumber = (val) => {
    try {
        let stringValue = val.toString();
        if (stringValue.lastIndexOf('%') >= 0) {
            return val;
        }
        let number = parseInt(val);
        if (isNaN(number)) throw val + " is not number";
        return number.toLocaleString('en-US');
    } catch (error) {
        console.log("err in addCommatoNumber:", error);
        return val;
    }
}
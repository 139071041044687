import React,{useState} from 'react';
// import Typography from '@material-ui/core/Typography';
import "./highlightpoints.css"
import { addCommatoNumber, addCurrencySuffix } from './helper';
import { Tooltip } from '@mui/material';

function HighlightPoints(props){
    const [showComma, setShowComma] = useState(false)
    const changeState=()=>{
        setShowComma(pre=>!pre);
    }
    return(
        <div className='maindiv'>
            
            
                <span  className='Heading' >{props.title}</span> 

                        <Tooltip title={addCommatoNumber(props.count)} enterDelay={100} leaveDelay={0} followCursor style={{"cursor":"pointer"}}   >
                    <span className='body' >
                        {addCurrencySuffix(props.count)}    
                    </span>
                        </Tooltip>
                <span className='bottom' >{props.relateddata}</span>

            
            </div>
            
            

    )
}

export default HighlightPoints;
import React from 'react'
import GridTable from '../grid_src'
export default function TableEffect() {
  return (
    <GridTable
        
    rows={Array(100).fill(null)} columns={[

{
  id: "2",
  field: "username",
  label: "",
},
{
  id: "3",
  field: "first_name",
  label: ""
},
{
  id: "4",
  field: "last_name",
  label: ""
},
{
  id: "12",
  field: "username",
  label: "",
},
{
  id: "43",
  field: "first_name",
  label: ""
},
{
  id: "124",
  field: "last_name",
  label: ""
},
{
  id: "52",
  field: "email",
  label: ""
},]} />
  )
}

import { Button } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, Typography, DialogTitle, IconButton, Tooltip,Snackbar,Alert,Paper, CircularProgress } from "@mui/material";
import PropTypes from 'prop-types';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import GridTable, { Loader } from "../grid_src";
import './contactReached.css'
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";
import { useEffect, useState } from "react";
import { PacmanLoader } from "react-spinners";
import { useRef } from "react";
import Papa from 'papaparse';
import TableEffect from '../skeletonEffectTable/tableEffect';
import { theme } from "..";



const customButton = styled(Button)(({ theme }) => ({
    '& .MuiButton-contained': {
        backgroundColor: '#21DA8C!important',
        color: 'white!important',
        borderRadius: '18px!important'
    }
}))



// const MyAwesomeTable = () => <GridTable columns={columns} rows={rows} 



export const SettingDialogBox = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        padding: 0
        //   paddingBottom:theme.spacing(2)

    },
    "& .MuiDialog-paperWidthSm": {
        minWidth: "65vw",
        maxWidth: "80vw",
        maxHeight:"80%",
        //   minHeight:"482px",
        //   maxHeight:"482px",
        
        padding: "16px 32px 24px 32px",
        overflow:"hidden",
        borderRadius: "24px",
        gap: "24px"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(10),

    },
    '& .MuiDialogActions-root': {
        justifyContent: "space-between",
        padding: theme.spacing(10),
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(5)
    },

}));
const BootstrapDialogTitle = (props) => {

    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0 }} >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {children}
                {onClose ? (
                    <Tooltip title={'Close'}>

                        <IconButton
                            aria-label="close"
                            onClick={() => props.onClose()}
                        // sx={{
                        //   position: 'absolute',
                        //   left: 800,
                        //   top: -30

                        // //   color: (theme) => theme.palette.grey[500],

                        // }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
        </DialogTitle>
    );

};
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
export const ContactReached = (props) => {
    
    const [Downlaoddata, setDownlaodData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactRow,setContactRow] = useState([]);
    const [contactColumn,setContactColumn] = useState([]);
    const selectRow = useRef([]);
    const [openSnackbar,setOpenSnackbar] = useState(false)
    const details = useRef({});
    // const [] = useState()
    function sentenecCase(key,sub) {
        try {
          
          const finalResult = props.contactMapper[key][sub];
      
          return finalResult;
        } catch (error) {
          return undefined;
        }
      }
    const getcontactReachedRecords = () => {
        setLoading(true)
        axios.post(window._env_.REACT_APP_UNIFIED_URL + "/analytics/programs/contacts/" + props.tenantId + `/?campaignId=${props.campaign?.id??''}&stepTitle=${props.campaign?.name??''}&icp=${props.icp}`,[], { withCredentials: true }).then(res => {
            console.log(res.data);
            if (!res.data?.success){
                details.current.success = 'error';
                details.current.message = res.data.message;
                setOpenSnackbar(true);
                setLoading(false)
                setContactRow([])
                return ;
            }
                const contactData = res.data.data;
                if(contactData.length==0){
                    setLoading(false)
                    setContactRow([])
                    return;
                }
                const contactKeys = Object.keys(contactData[0]);
                let contactColumn =[]
                contactKeys.forEach((key, index) => {
                    const obj = {
                        id: index + 1,
                        field: key,
                        label: sentenecCase(key, "label"),
                        order: sentenecCase(key, "order"),
                        visible:sentenecCase(key,"visible")
                    }
                    contactColumn.push(obj)
                })
                contactColumn  = contactColumn.filter(e=>e?.label).sort((a,b)=>{
                    return a.order  - b.order
                });
                contactColumn.unshift({
                    id:"checkbox",
                    label:""
                  })
                setContactColumn(contactColumn);
                setContactRow(contactData.map(obj=>{obj.id=obj.contactId; return obj;}));
                setLoading(false);
                return;
            
        }).catch(err => {
            console.log(err);
            setContactRow([])
            return;

        })

    }

    function convertToCsv(jsonData) {
        const csv = Papa.unparse(jsonData);
        return csv;
      }
      
      function downloadCsv(csv) {
        const blob = new Blob([csv]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export${new Date().toISOString()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      
      function handleDownload() {
        const csv = convertToCsv(Downlaoddata);
        downloadCsv(csv);
      }
      
      
      
    useEffect(() => {
        if (props.open) {
            getcontactReachedRecords();
        }
    }, [props.open])


    return (
        <ThemeProvider theme={theme}>
            <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={()=>{setOpenSnackbar(false)}}
>
<Alert onClose={()=>{setOpenSnackbar(false)}} severity={details.current.success??'info'}>{details.current.message??''}</Alert>
</Snackbar>
        <SettingDialogBox open={props.open}>
            <BootstrapDialogTitle onClose={() => { props.handleContactDialog('') }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>Contacts Reached through {props.campaign?.name}</Typography >
            </BootstrapDialogTitle>
            {loading ?
            <Paper style={{ borderRadius: '18px', padding: `5px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`   }}>
                <TableEffect/>
                </Paper>
                : 
                <Paper style={{ borderRadius: '18px', padding: `5px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`   }}>

                
                <GridTable columns={contactColumn} rows={contactRow} selectRow={selectRow}  
                isPaginated={false}
                onSelectedRowsChange={(arr,wholeObj)=>{

                    const columns =  wholeObj.columnsApi.visibleColumns;
                    const row = wholeObj.rowsApi.rows;
                    // console.log(arr,wholeObj,columns,row,"heelo ")
                    const selectRow1 = row.filter(e=>arr.includes(e.id));
                    const updatedData = selectRow1.map(obj => {
                        const updatedObj = {};
                        for (const column of columns) {
                            if(column.label == undefined || obj[column.field] === undefined) continue;
                          updatedObj[column.label] = obj[column.field];
                        }
                        return updatedObj;
                      });

                      console.log("updatedData",updatedData);
                    setDownlaodData(updatedData)
                    
                }} />
               </Paper>
            }
            <div className="buttonaction" >
                <Button style={{ color: "#21DA8C",fontWeight:600 }} variant="text" onClick={() => { props.handleContactDialog('') }}>Back</Button>
                {/* <CsvDownload data={Downlaoddata} /> */}

                <Button disabled={Downlaoddata.length==0&&true}  style={Downlaoddata.length>0?{ borderRadius: "18px",background:"#21DA8C",color:"whitesmoke",fontWeight:600 }:{borderRadius: "18px",fontWeight:600}} color='primary'  variant="contained" onClick={handleDownload} > <DownloadIcon />  Download</Button>
            </div>
            {/* <DialogActions>
            </DialogActions> */}
        </SettingDialogBox>
        </ThemeProvider>
    )
}

import React,{useContext} from 'react'
import { Fragment } from 'react'
import { Dialog, DialogTitle, DialogContent, Tooltip, IconButton, DialogActions, Button, styled, Divider } from '@mui/material'
import { Add, Cancel, Check, Clear, Close } from '@mui/icons-material';
import Slide from '@mui/material/Slide';

import Header from './Header';
import AttributeList from './AttributeList';
import {createTheme } from '@mui/system';
import { filterContext } from './Filters';

export const SettingDialogBox = styled(Dialog)(({ theme }) => ({
    
    '& .MuiDialogTitle-root':{
      padding:0
    //   paddingBottom:theme.spacing(2)
  
    },
    "& .MuiDialog-paperWidthSm":{
    //   minWidth: "1240px",
      minWidth:"54vw",
      overflow:"hidden",
      maxHeight:"80vh",
    //   minHeight:"482px",
    //   maxHeight:"482px",
      padding:"24px",
      borderRadius:"24px",
      gap:"24px"
  },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
      
    },
    '& .MuiDialogActions-root': {
      justifyContent:"flex-end",

      padding: theme.spacing(0),
      paddingTop:theme.spacing(0),
      marginTop:theme.spacing(0)
    },
   
}));
export default function Modal(props) {
    const {tenantMapperData,icp,tenantId,attributeArr,handleSave,checkSave,handlingSave} = useContext(filterContext);
    console.log("tenantConfigMapper",tenantMapperData)
    
    // const Transition = React.forwardRef(function Transition(props) {
    //     return <Slide direction="up"  {...props} />;
    //   });
    const [checked,setChecked] = React.useState(false);

  return (
    <Fragment>
        <SettingDialogBox   open={props.open}  >
            <DialogTitle id="customized-dialog-title" className='dialtitle' onClose={props.handleClose}>
                    <Header filterName={props.filterName} setFilterName={props.setFilterName} checked={checked} setChecked={setChecked} handleSave={handleSave} actionType={props.actionType} setAction={props.actionType} />
                    <Tooltip disableFocusListener title="Close">
                        <IconButton aria-label="close" >
                            <Close
                                // style={{margin:"20px"}}
                                onClick={(e) => {
                                    // open another dialog to confirm close
                                    props.handleClose();
                                }}
                            />
                    </IconButton>                    
                </Tooltip>
                
            </DialogTitle>
            <Divider/>
            <DialogContent className='filterbody'>
                {attributeArr.map((e,index)=>
                    <AttributeList key={e.attrId} elementIndex={index} attribute={e}
                    />
                )
                }
                {/* showError={showError}
                props.filterName = {props.filterName} 
                attributeList = {attributeDataByName}
                getAttributeData = {getAttributeList} */}
                {/* 
                filter={filter} saveFilterName={props.filterName => setFilterName(props.filterName)} filterOptions={filterOptions}
                 */}
            </DialogContent>
            <DialogActions>
                    {/* {
                        editMode ? 
                        <Tooltip disableFocusListener title="Apply Filters">
                            <Button onClick={e => saveFilters("edit")} color="primary">Save Filter</Button>
                        </Tooltip>
                        :
                        <Checkbox
                            checked={checked}
                            onChange={e=> {         
                                setChecked(e.target.checked)
                            }}
                            value="checked"
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            label="Save to Database"
                        />
                    }
                     */}
                     <Tooltip disableFocusListener title="Cancel">
                    <Button style={{fontWeight:500,borderRadius:"16px"}} onClick={props.handleClose} variant='outlined' color="primary"> <Clear/> Cancel</Button>
                    </Tooltip>
                    <Tooltip disableFocusListener title="Apply Filter">
                    <Button   variant='contained' color='primary' style={{color:"white",borderRadius:"16px"}}  onClick={async()=>{if(!checkSave()){
                        if(checked)  handlingSave(attributeArr.slice(1),props.filterName).then(res=>props.setFilterName(''))
                        else{
                        props.handleClose();handleSave(attributeArr.slice(1))
                        }
                        }}}><Check/> Apply filter</Button>
                    </Tooltip>
                </DialogActions>
        </SettingDialogBox>
    </Fragment>
  )
}

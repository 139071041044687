import { CloseRounded } from '@mui/icons-material'
import { Chip, Tooltip } from '@mui/material'
import React from 'react'

export default function ShowChip({body, setBody, handleFilter,tenantId}) {
    
    const handleDelete=(data)=>{
        setBody(chip=>chip.filter(e=>e.attrId !== data.attrId))
        sessionStorage.setItem('filter'+tenantId,JSON.stringify(body.filter(e=>e.attrId !== data.attrId)))
    }
    const handleRemoveAll=()=>{
        setBody([]);
        sessionStorage.setItem('filter'+tenantId,"[]")
    }
   return(
    <div style={{display:'flex', flexWrap: "wrap",overflow:"hidden", gap:"15px"}}> 
    {body.map((data, index) => {
        return (
          <div key={data.key} style={{ margin: "0px" }}>
            <Tooltip title={  (
            <>
            {data.relation} 
            {!(data.relation =='is empty' || data.relation == 'is not empty') ?
            <>
            (
    {data.type === "list" 
      ? data.values.slice(0, 2).map(e => e.programName).join()
      :data.relation === 'between'?
       data.values.slice(0, 2).join():data.values.slice(0, 1)
            })
            </>:<></>
          }
            </>)
          }
        >
              <Chip
                
                style={{ margin: "10px 10px 10px 0px", backgroundColor: "rgba(89, 89, 89, 1)", color: "#FFFFFF", fontSize: "small",borderRadius:"18px" }}
                variant="outlined"
                // backgroundColor="#8A8A8A"
                size="medium"
                label={(
                    <div style={{display:'flex',gap:"2px"}}>
  {data.attribute}
 
</div>
                )}
                onDelete={()=>handleDelete(data)}
                onClick={handleFilter}
                deleteIcon={<CloseRounded style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
              />
            </Tooltip>
          </div>
        );
      })}
    {body.length > 0 && <h4 style={{ fontSize: "12px", color: "#21DA8C", cursor: "pointer" }} onClick={() => handleRemoveAll()}><u>REMOVE ALL</u></h4>}

      </div>
   )
}

import React from "react";
// import "./../styleNew.css"

// import './styleNew.css';

const Header = ({
  tableManager,
    
}) => {
  const {
    config: {
      showColumnVisibilityManager,
      components: { ColumnVisibility, Search },
      additionalProps: { header: additionalProps = {} },
      showSearch,
    },
    columnsApi: { columns },
    columnsVisibilityApi: { toggleColumnVisibility },
    searchApi: { setSearchText, searchText },
  } = tableManager;
  console.log("visiblity", toggleColumnVisibility, columns);
  const classNames = (
    "rgt-header-container " + (additionalProps.className || "")
  ).trim();

  return (
    <>
    <div {...additionalProps} className={classNames}  >
      {showSearch !== false ? (
        <Search
          value={searchText}
          onChange={setSearchText}
          tableManager={tableManager}
        />
        
      ) : (
        <span></span>
      )}
      <div style={{ marginLeft: "10px", display: "flex" }}>
        

        {showColumnVisibilityManager !== false ? (
          <div style={{ display: `flex`}}>
            <ColumnVisibility 
              columns={columns.filter((c) => {
                if (c.columnVisibility != false) {
                  return c;
                }
              })}
              onChange={toggleColumnVisibility}
              tableManager={tableManager}
            />
          </div>
        ) : (
          <span></span>
        )}
      </div>
    </div>
    </>
  );
};

export default Header;

import React,{ useEffect, useRef, useState }from 'react';
import "./topGridEmailView.css";
// import Typography from '@material-ui/core/Typography';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./highlightpoints.css"
import HighlightPoints from './highlightPoints';
import { Header } from './campaign/Header';
import { Divider, Fab } from '@mui/material';
import SearchBar from './SearchBar';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { addCommatoNumber } from './helper';

function SegmentDate(props){
    // const [value, setValue] = useState('');
    const [products,setProduct] = useState([]);
    const headerRef = useRef(null);
    const [scrollPosition,setScrollPosition] = useState(0);
 
    const handleHeaderScroll =(event)=>{
        try {
          // console.log("handleHeaderScroll....",event.currentTarget.scrollLeft);
          setScrollPosition(event.currentTarget.scrollLeft);
        } catch (error) {
          console.error("error occurred in handleHeaderScroll",error);
        }
      }
        console.log("props.campaignData--->",props.campaignData);

    useEffect(()=>{
        if(props.campaignData){
       setProduct([
        {"title":"ACCOUNTS REACHED", "count":props.campaignData.totalaccountreached??"0", "relateddata":`${addCommatoNumber(props.campaignData.totalcontactreached)??"0"} Contacts Reached`},
        {"title":"WEBFORM FILLS", "count":props.campaignData.totalwebformfills??"-", "relateddata":`${props.campaignData.webformfillsper??"-"}% of ${addCommatoNumber(props.campaignData.webformfill) ?? "0"} Total Webform Fills`},
        {"title":"PIPELINE INFLUENCED", "count":`${props.campaignData.totalpipeline??'0'}`, "relateddata":`${addCommatoNumber(props.campaignData.totalopportunity_pipeline)??'0'} Opportunities`},
        {"title":"REVENUE", "count":`${props.campaignData.totalrevenue??'0'}`, "relateddata":`${addCommatoNumber(props.campaignData.totalopportunity_revenue)??'0'} Opportunities`},
        {"title":"EMAILS DELIVERED", "count":props.campaignData.totalemaildelivered??'-', "relateddata":`${props.campaignData.totalemaildeliveredper??"-"}% of Emails Sent`},
        {"title":"EMAIL OPEN RATE", "count":`${props.campaignData.totalemailopenedper??'-'}%`, "relateddata":`${addCommatoNumber(props.campaignData.totalemailopened)??'-'} Emails Opened`}
            // {"title":"Qualified Leads","count":`${props.campaignData.totalQualifiedLeads??'-'}`,"relateddata":`${props.campaignData.totalOpportunites??'-'} Opportunites`},
                ])
            }
        },[props.campaignData])
        const handleScrollAction=(offset)=>{
            // headerRef.current.scrollIntoView({ behavior: "smooth" });
            // console.log("headerRef",headerRef.current.scrollRight);
            console.log("headerRef..",headerRef.current.scrollLeft,headerRef.current.scrollWidth-headerRef.current.clientWidth);
            if(headerRef.current){
              headerRef.current.scrollLeft+=offset;
              setScrollPosition(headerRef.current.scrollLeft);
            }
           
          }
        return(
            <div style={{overflow: "-moz-hidden-unscrollable"}} > 
            <div>
            <Header  screen={"2"} hideHeader={props.hideHeader} campaignName={props.campaignName} GetParentStatus={props.GetParentStatus} segment={sessionStorage.getItem(`campaignName${props.tenantId}`)??''} icp={props.icp} tenantId={props.tenantId} toggle={props.toggle} handleToggle={props.handleToggle}  />

  
            <SearchBar setSearchInput={props.setSearchInput} />
            <Divider className='divider'/>
            {/* <div><HighlightPoints></HighlightPoints></div> */}
         <div>
         <div ref={headerRef} id='parentScroll' className='headerscroll' onScroll={handleHeaderScroll} >          
                {products.map(
                    (ele) => (
                        <HighlightPoints
                        title={ele.title}
                        count={ele.count}
                        relateddata={ele.relateddata}
                        />
                    )
                )}
                {scrollPosition>0&&
            <div onClick={()=>handleScrollAction(-250)} id='btn-right' style={{top:"30%", position:"absolute", left:"5%"}} ><Fab size="small" className="circle" variant="circular"  > < ChevronLeftOutlined  /> </Fab> </div>
            }
            {( scrollPosition==0 || scrollPosition<=(headerRef.current?.scrollWidth-headerRef.current?.clientWidth-1))&&
            <div onClick={()=>handleScrollAction(250)} id='btn-right' style={{top:"30%",position:"absolute", left:"95%"}} ><Fab size="small" className="circle" variant="circular"> < ChevronRightOutlined  /> </Fab> </div>
            }
                </div>
                </div>
         </div>
        </div>
        
    )
}

export default SegmentDate;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from "@bamboobox/b2logincheck";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'
import RouterApp from './RouterApp';
// import {AUTH_URL,LOGIN_URL} from './constants';
console.log("LOGIN_URL:",window._env_.REACT_APP_LOGIN_URL);
const root = ReactDOM.createRoot(document.getElementById('root'));

export const theme = createTheme({
  palette: {
    primary: {
      main: "#21DA8C",
    },
    tab:{
      main:"#595959"
    },
    buttongroup:{
      main:"rgba(255, 255, 255, 1)"
    },
    buttongroupactive:{
      main:"rgba(211, 248, 232, 1)",
    }
  },
});

// console.log("auth..",AUTH_URL,LOGIN_URL)
const resources = [
  "campaign_hub",
  "CampaignHub_Email",
  "CampaignHub_LinkedInAd",
  "CampaignHub_GoogleAd",
];

root.render(
  <AuthProvider loginURL={window._env_.REACT_APP_LOGIN_URL} authcheckURL={window._env_.REACT_APP_AUTH_URL}>
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <ResourceCheck component={<RouterApp />} resources={resources} />
    {/* <RouterApp /> */}
    </ThemeProvider>
  </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Header } from "./campaign/Header"
import { useAuthState } from '@bamboobox/b2logincheck';
import { useColumnVisibilityPersist, useColumnSortOrderPersist } from "@bamboobox/b2gridutils";
import { toast } from "react-toastify";
import Alert from '@mui/material/Alert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Fab from '@mui/material/Fab';
import {getSelectedSegments} from "./api";
// import GridTable from './grid_src';
import CircularProgress from '@mui/material/CircularProgress';
// import data from "./data.json";
import { GridView } from './campaign/GridView';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useEffect, useRef, useState,Fragment,lazy,Suspense } from 'react';
import SegmentDate from './topGridEmailView';
import "./highlightpoints.css"
// import{  useLocation } from "react-router-dom";
import SearchBar from './SearchBar';
import { Paper, ButtonGroup, Button, Skeleton, createTheme, ThemeProvider, Snackbar  } from '@mui/material';
import './App.css'
import {  useNavigate, useLocation } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { Tabs, Tab } from "@mui/material";
import styled from "@emotion/styled";
import { ParentGridTable } from "./ParentGridTable";
import HighlightPoints from "./highlightPoints";
import Filters from "./filters/Filters";
import axios from "axios";
import ShowChip from "./filters/ShowChip";
import { ChevronLeft, ChevronLeftOutlined } from "@mui/icons-material";
import { warning } from "@remix-run/router";
import { addCommatoNumber } from "./helper";
const  DisplayCampaign = lazy(async() =>{ 
  let obj = await import("./campaign/DisplayCampaign")
  return typeof obj.default === 'function' ? obj : obj.default
}) ;
const DisplayGoogleCampaign = lazy(async() =>{ 
  let obj = await import("./googleads/DisplayGoogleCampaign")
  return typeof obj.default === 'function' ? obj : obj.default
}) ;
const CustomTab = styled(Tab)(({ theme })=>({
    root: {
      textTransform: "none",
      minWidth: "13.8vw",
      width: "13.8vw",
      lineHeight: "15.83px",
      fontFamily:'Nunito Sans !important',
      padding: theme.spacing(1),
      display: "inline",
       minWidth: "100px",
        width: "fit-content",
         margin:"0px 10px",
         color:"#A0A0A0",
         fontWeight:400,
         fontSize:"13px!important"
    },
    "& .Mui-selected":{
        color:"#595959!important",
        fontWeight:"700"
    }
    
  }));
  const CustomTabs = styled(Tabs)(({ theme })=>({
    root: {
        borderBottom: '1px solid #D3D3D3', 
        color: "#A0A0A0"
    },
    '& .MuiTabs-flexContainer':{
        justifyContent:"center",
        gap:"16px",
    }
    
  }));
  // export function useQuery() {
  //   const location = useLocation()
  //   return new URLSearchParams(location.search);
  // }
export const Skelton = ()=>(
        
        
            <div style={{display:"grid",justifyItems:"center"}}>
            <Skeleton variant="text" width={180} animation="pulse" />
              <Skeleton variant="text" width={70} animation="pulse" />
              <Skeleton variant="text" width={180} animation="pulse" />
            </div>

)
function pad(number, length) {
  var str = "" + number
  while (str.length < length) {
    str = '0' + str
  }
  return str
}
function setTimeZoneOffset() {
  const d = new Date();
  let offsetDiff = d.getTimezoneOffset();
  let sign = ''
  let str = ''
  offsetDiff < 0 ? sign = '+' : sign = '-'
  str = sign + pad(parseInt(Math.abs(offsetDiff / 60)) + ':' + pad(Math.abs(offsetDiff % 60),))
  console.log(str)
  if (str != '') {
    return str;
  }
  return '+0.0'
}

export const configerTenatMapper=(obj)=>{
  const newArr = []
  try{
  if(typeof(obj)==='object'){
    let tempobj = {}
    for(let key of Object.keys(obj)){
      tempobj= obj[key];
      tempobj['key'] = key;
      newArr.push(tempobj);
    }
  }
  return newArr
}catch(err) {console.error("configerTenatMapper:",err)}
  return newArr;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#21DA8C",
    },
    tab:{
      main:"#595959"
    },
    buttongroup:{
      main:"rgba(255, 255, 255, 1)"
    },
    buttongroupactive:{
      main:"#D3F8E8",
    }
  },
});

export const ParentScreen=(()=>{

    const location = useLocation()
    function useQuery() {
      return new URLSearchParams(location.search);
    }
    const navigate = useNavigate()
    const userDetails = useAuthState();
    console.log("userDetails",userDetails);
    const tenantId = useRef(userDetails.user.tenantId);
    const uuid = useRef(userDetails.user.userUuid);
    const [action,setAction] = useState(sessionStorage.getItem("Action"+tenantId.current)??'All');
    const [openFilter,setOpenFilter] = useState(false);
    const [open,setOpen] = useState(false);
    const [tenantMapperData,setTenantMapperData] = useState({});
    const [tenantMapperDataDisplay,setTenantMapperDataDisplay] = useState({});
    const [tenantMapperGoogleAds,setTenantMapperGoogleAds] = useState({});
    const [tabValue,setTabValue] = useState(0);
    const [parentData,setParentData] = useState([]);
    const [isLoading,setLoading] = useState(true)
    const [products,setProducts] = useState([]);
    const [page,setPage] = useState(1);
    const [segment,setSegment] = useState(sessionStorage.getItem(`campaignName${tenantId.current}`)??'');
    const [segType,setSegType] = useState(sessionStorage.getItem(`type${tenantId.current}`)??'');
    const [startdate,setstartdate] = useState(sessionStorage.getItem('fromDate')??'');
    const [enddate,setenddate] = useState(sessionStorage.getItem('toDate')??new Date());
    const [isnext,setIsnext] = useState(false);
     const [body,setBody] = useState(JSON.parse(sessionStorage.getItem("filter"+tenantId.current))??[]);
    const [downloadattr,setdownloadattr] = useState([]);
    const [downloadclick, setdownloadclick] = useState(false);
    const offset = useRef(setTimeZoneOffset());
    const [opensnack,setOpensnack] = useState(false);
    const [snack,setSnack] = useState({open:false});
    const [relationValue,setRelationValue] = useState(); 
    const [status,setStatus] = useState('InActive');
    const CancelToken = useRef(null);
    let [count,setCount] = useState(0);
    const headerRef = useRef(null);
    const [getColumnSortDetails, setColumnSortDetails] =
    useColumnSortOrderPersist("B2ParentCampaignHViewListing" + tenantId.current);
    let [sort, setSort] = useState({ colId: getColumnSortDetails("sortColumnNo"), isAsc: getColumnSortDetails("sortOrderBool") });
    const [sortby, setSortby] = useState(getColumnSortDetails("sortBy"));
    const [sortType, setSortType] = useState(getColumnSortDetails("sortType"));
    const [scrollPosition,setScrollPosition] = useState(0);
    const permissionsOfUser = userDetails?.permissions.map((obj)=>obj.resourceName.toLowerCase());
    const resources=new Map([["campaignhub_email","Email Campaigns"],["campaignhub_linkedinad","LinkedIn AD campaigns"],["campaignhub_googlead","Google AD campaigns"]]) 
  let resourcesAllowed=[]
  if(permissionsOfUser.includes('campaign_hub')){
    resourcesAllowed=Array.from(resources.values());
  }else{
    Array.from(resources.keys()).forEach(obj => {
      if(permissionsOfUser.includes(obj)){
        resourcesAllowed.push(resources.get(obj));
      }
    })
  }
  console.log("resources",resourcesAllowed,permissionsOfUser);
  
  let GetIcp = () => {
    console.log('icp logger here', useQuery().get("icp"));
    // console.log(useQuery().get("icp"))
    return useQuery().get("icp");

  }
  let query = useQuery();
  let params_url = query.get("icp") || "";
  let [icp,setIcp] = useState(params_url);
  const keyToScreenName={
    "Email Campaigns":"ParentcampaignHub-creation",
    "LinkedIn AD campaigns":"displayadscreen",
    "Google AD campaigns":"google_ads_campaign",
  }
  const [screenName,setScreenname]=useState(keyToScreenName[resourcesAllowed[0]]);

//   useEffect(() => {
//     console.log("test icp query changed")
//     setIcp(GetIcp())
// }, [icp])

    const [searchInput,setSearchInput] = useState('');

    useEffect(() => {
      console.log("test icp query changed")
      setIcp(GetIcp())
  }, [useQuery().get("icp")])

  useEffect(() => {
    if(query.get("tab") === "linkedin"){
      console.log('redirect from linkedin child page')
      handleChange("", 1);
    } else if(query.get("tab") === "googleads" || query.get("tab") === "googlegroup"){
      console.log('redirect from google ads child page')
      handleChange("", 2);
    }
  }, [useQuery().get("tab")])


    const handleClick=(name)=>{
        setAction(name);
        sessionStorage.setItem("Action"+tenantId.current,name);
    }

    

    function handleChange(e, newValue) {
      const screenName=keyToScreenName[resourcesAllowed[newValue]];
      // console.log("zzzzz",newValue,screenName);
      //   if(newValue === 0) {
      //     query.delete("tab");
      //     navigate("?" + query.toString())
      //   }else if(newValue === 1){
      //     getTenantMapperScreen(screenName)
      //   }else if(newValue === 2){
      //     getTenantMapperScreen(screenName)
      //   }
      setScreenname(screenName)
      setTabValue(newValue);
    }
    const getTenantMapper = async()=>{
      try {
        const tenantResponse = await axios.get(`${window._env_.REACT_APP_TENANT_CONFIG}/tenant/field/mapper/get/${tenantId.current}/ParentcampaignHub-creation`,{ withCredentials: true });
        // setResponse(tenantResponse.data);
        setTenantMapperData(tenantResponse.data);
        
      } catch (error) {
        console.error(error)
        setTenantMapperData({});
      }
  
    }
    
    const handleHeaderScroll =(event)=>{
      try {
        // console.log("handleHeaderScroll....",event.currentTarget.scrollLeft);
        setScrollPosition(event.currentTarget.scrollLeft);
      } catch (error) {
        console.error("error occurred in handleHeaderScroll",error);
      }
    }
    const getTenantMapperScreen = async(screenName)=>{
      try {
        const tenantResponse = await axios.get(`${window._env_.REACT_APP_TENANT_CONFIG}/tenant/field/mapper/get/${tenantId.current}/${screenName}`,{ withCredentials: true });
        console.log("tenant response:"+screenName,tenantResponse);
        if(screenName === 'displayadscreen'){
          setTenantMapperDataDisplay(tenantResponse.data)
        }else if(screenName === 'google_ads_campaign'){
          setTenantMapperGoogleAds(tenantResponse.data)
        }else{
          setTenantMapperData(tenantResponse.data);
        }
      } catch (error) {
        console.error("error occurred in getTenantMapperScrren");
        if(screenName === 'displayadscreen'){
          setTenantMapperDataDisplay({})
        }else if(screenName === 'google_ads_campaign'){
          setTenantMapperGoogleAds({})
        }else{
          setTenantMapperData({});
        }
      }
    }
    useEffect(() => {
      (getRelationObj());
      // getTenantMapper();
      
    }, [])

    useEffect(()=>{
      getTenantMapperScreen(screenName)
    },[screenName])

    const getRelationObj =  () => {
      try {
        const requestBdy = {
          tenantId: tenantId.current,
          tool: "b2_campaign",
          keys: ["filters_relation"],
        };
         return fetch(`${window._env_.REACT_APP_TENANT_CONFIG}/tenant/get-tenant-config-json`, {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBdy),
        })
          .then((response) => response.json())
          .then((resp)=>{
              setRelationValue(resp.data.configs[0].configVal);
          })
        } catch {
          console.log("unable to fetch ");
          setRelationValue([])
        }
    }
    const handleFilter=()=>{
        setOpenFilter(toggle=>!toggle)
    }
    useEffect(()=>{
      if(downloadclick === true)
      handleDownload(tenantId,icp,sortby,sortType,searchInput,action,segType,segment);
  },[downloadattr])
    
    const handleDownload = async(tenantId,icp,sortby,sortType,searchInput,action,segType,segment) =>{
      console.log("In download");
      setdownloadclick(false);
      toast.info(<Alert severity="info"> Download of is in progress</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
      const requestBdy = {
        download:downloadattr,
        filters:body
      };
      await fetch(`${window._env_.REACT_APP_UNIFIED_URL}/analytics/parentprograms/${tenantId.current}?page=1&
      limit=150&icp=${icp}&sortby=${sortby}&sortType=${sortType}&search=${searchInput}&campSegmentName=${segment}
      &segmentType=${segType}&status=${action}&campaignstartdate=${startdate}&campaignenddate=${enddate}&download=true`,{
                    method: "POST",
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify(requestBdy),
                }).then(res=>res.text())
                .then(retData=>{
                  let csvContent = new Blob([retData], { type: 'text/csv' })
                  let encodedUri = URL.createObjectURL(csvContent);
                  let link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                      link.setAttribute("download", `Download_Campaign.csv`);
                      document.body.appendChild(link);
                      link.click();
                }).catch(err=>{
                  console.error("error in fetching download in parent campaign:",err);
                })
    }
    
    const handleSave=(arr)=>{
      setBody(arr)
      sessionStorage.setItem("filter"+tenantId.current,JSON.stringify(arr)) 
    }
    
    const parentTableData = async()=>{
        try {  
            if (CancelToken.current) {
          CancelToken.current.abort();
        }
        const controller = new AbortController();
        CancelToken.current = controller;
      
            const parentRespo = await fetch(`${window._env_.REACT_APP_UNIFIED_URL}/analytics/parentprograms/${tenantId.current}?page=${page}&limit=150&icp=${icp}&sortby=${sortby}&sortType=${sortType}&search=${searchInput}&campSegmentName=${segment}&segmentType=${segType}&status=${action}&campaignstartdate=${startdate}&campaignenddate=${enddate}`,{
                method: "POST",
                body:JSON.stringify({filters:body}),
                headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                },
                signal:CancelToken.current?.signal,
                credentials: "include"
            });
            let parentData = await parentRespo.json();
            if(!parentData.success){
                throw 'error in fetching data'
            }
            parentData = parentData.data
            // console.log(parentData.data);
            setParentData(parentData);
            setProducts([
            {"title":"ACCOUNTS REACHED", "count":parentData.totaldata.totalaccountsreached??"0", "relateddata":`${addCommatoNumber(parentData.totaldata.totalcontactsreached)??"0"} Contacts Reached`},
            {"title":"WEBFORM FILLS", "count":parentData.totaldata.totalwebformfills??"-", "relateddata":`${parentData.totaldata.webformfillsper??"-"}% of ${addCommatoNumber(parentData.totaldata.webformfill) ?? "0"} Total Webform Fills`},
            {"title":"EMAIL OPEN RATE", "count":`${parentData.totaldata.totalemailopenedper??'-'}%`, "relateddata":`${addCommatoNumber(parentData.totaldata.totalemailopened)??'-'} Emails Opened`},

            {"title":"PIPELINE INFLUENCED", "count":`${parentData.totaldata.totalpipeline??'0'}`, "relateddata":`${addCommatoNumber(parentData.totaldata.totalopportunity_pipeline)??'0'} Opportunities`},
            {"title":"REVENUE", "count":`${parentData.totaldata.totalrevenue??'0'}`, "relateddata":`${addCommatoNumber(parentData.totaldata.totalopportunity_rev)??'0'} Opportunities`},

            // {"title":"Qualified Leads","count":`${parentData.totaldata.totalQualifiedLeads??'-'}`,"relateddata":`${parentData.totaldata.totalOpportunites??'-'} Opportunites`},
            {"title":"EMAILS SENT" ,"count":parentData.totaldata.totalemailsent??'-', "relateddata":`${addCommatoNumber(parentData.totaldata.totalemails)??"-"} unique Emails`},
            {"title":"EMAILS DELIVERED", "count":parentData.totaldata.totalemailsdelivered??'-', "relateddata":`${parentData.totaldata.totalemailsdeliveredper??"-"}% of Emails Sent`},
            ])
            setIsnext(false);
            setLoading(false);
        } catch (error) {
          if(error?.message !== "The user aborted a request."){
            console.log("error in campaignHubResult",error)
            // setCampResponse([]);
            setIsnext=(false); 
            console.log("parentTable error ",error);
            setSnack({
              open:true,
              action:"error",
              message:"Failed to fetch Records",
              caption:'Please Try Again! After some Time 🙂',
              })
              setOpensnack(true)
          }

        }
    }
    // useEffect(() => {
    //   const handlePageShow = (event) => {
    //     if (event.persisted) {
    //       window.locat ion.reload();
    //     }
    //   };
    //   window.onpageshow = handlePageShow;
    //   return () => {
    //     window.onpageshow = null;
    //   };
    // }, []);

   // eslint-disable-next-line react-hooks/exhaustive-deps

   

  
  
   useEffect(() => {
     if(tabValue!==0 && segType=='contact' && segment!==''){
      sessionStorage.setItem(`campaignName${tenantId.current}`,'')
      setSegment('');
      setSnack({
        open:true,
        action:"warning",
        message:"Display ads cannot be segmented based on Contacts",
        caption:'Segments will be reset to "All Campaign"',
        })
        setOpensnack(true)
     }
   }, [tabValue])
   

    useEffect(()=>{
      if(tabValue===0){
      console.log("calling.....1")
      setIsnext(true);
        parentTableData();
      }
    },[page,sortType,sortby])
    // const handleToggle=(value)=>{
    //   setToggle(value);
    //   sessionStorage.setItem(`toggle${tenantId.current}`,value);
    // }
    useEffect(()=>{
      if(tabValue===0){
      console.log("calling..........")
      setLoading(true)
      // setIsnext(true);
      if(page!=1) setPage(1);
      parentTableData();
      }
    },[searchInput,action,segment,segType,body,startdate,enddate,tabValue])
    const handleScrollAction=(offset)=>{
      // headerRef.current.scrollIntoView({ behavior: "smooth" });
      // console.log("headerRef",headerRef.current.scrollRight);
      console.log("headerRef..",headerRef.current.scrollLeft,headerRef.current.scrollWidth-headerRef.current.clientWidth);
      if(headerRef.current){
        headerRef.current.scrollLeft+=offset;
        setScrollPosition(headerRef.current.scrollLeft);
      }
     
    }
    const componentMap={
      "Email Campaigns":(
        <Fragment>
              <div className="midHeading">
              <div className="topheading" >
              <SearchBar setSearchInput={setSearchInput} />
              {/* whatever is added in the main api call please pass parameters in download button also */}
              <Button size='small'  variant="contained" onClick={()=>setOpen(true)} style={{fontFamily:"Nunito Sans",color:"#FFFFFF",fontWeight:800,borderRadius:"16px" }}> <DownloadIcon/>  Download</Button> 
              </div>
              <Filters relation={relationValue} tenantMapperData={configerTenatMapper(tenantMapperData)} tenantId={tenantId.current} handleSave={handleSave} body={body} handleFilter={handleFilter} open={openFilter} setOpen={setOpenFilter} screen={"parentcampaign"} enddate={enddate} startdate={startdate} />
              </div>
              {/* <div style={{overflow:"scroll" , scrollbarWidth:"none"}}> */}
               <ShowChip body={body} setBody={setBody} handleFilter={handleFilter} tenantId={tenantId.current} />
               <div ref={headerRef} id='parentScroll' className='headerscroll' onScroll={handleHeaderScroll} >     
              {!isLoading?      
               products.map(
                 (ele) => (
                   <HighlightPoints
                   title={ele?.title}
                      count={ele?.count}
                      relateddata={ele?.relateddata}
                      />
                      )
                      ):
                      (Array(4).fill(null)).map(e=><Skelton />)
          }
          {!isLoading&&scrollPosition>0&&
          <div onClick={()=>handleScrollAction(-250)} id='btn-right' className="circle" style={{top:"32%", position:"absolute", left:"5%"}} ><Fab size="small" className="circle"  variant="circular"  > < ChevronLeftOutlined  /> </Fab> </div>
          }
          {!isLoading&&( scrollPosition==0 || scrollPosition<=(headerRef.current?.scrollWidth-headerRef.current?.clientWidth-1))&&
          <div onClick={()=>handleScrollAction(250)} id='btn-right' style={{top:"32%",position:"absolute", left:"95%"}} ><Fab size="small" className="circle" variant="circular" > < ChevronRightIcon  /> </Fab> </div>
          }
           {/* {<div ><Fab style={{position:"absolute", color:"#000000"}} >< ChevronRightIcon /></Fab></div>} */}
            </div>
              {/* </div> */}
          <Paper style={{ borderRadius: '18px', padding: `8px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF` ,marginBottom:"8px"  }}>
          <ParentGridTable setOpen={setOpen} open={open} isLoading={isLoading} parentData={parentData} page={page} setPage={setPage} tenantId={tenantId.current} setColumnSortDetails={setColumnSortDetails} sort={sort} sortby={sortby} sortType={sortType} setSort={setSort} setSortType={setSortType} setSortby={setSortby} setCampResponse={setParentData} tenantMapperData={tenantMapperData}  downloadattr={downloadattr} setdownloadattr = {setdownloadattr} downloadclick={downloadclick} setdownloadclick={setdownloadclick} icp={icp} isnext={isnext} status={status}  />
          </Paper>
          </Fragment>
      ),
      "LinkedIn AD campaigns":(<Suspense fallback={<div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"52vh"}}>
      <CircularProgress style={{color:"#21DA8C"}}/>
      </div>} >
      <DisplayCampaign segment={segment} tenantId={tenantId.current} action={action} tenantMapperDataDisplay={tenantMapperDataDisplay}  icp={icp} relationValue={relationValue} startdate={startdate} enddate={enddate}   />   
  
      </Suspense>),
      "Google AD campaigns":(<Suspense fallback={<div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"52vh"}}>
      <CircularProgress style={{color:"#21DA8C"}}/>
      </div>} >
      <DisplayGoogleCampaign segment={segment} tenantId={tenantId.current} action={action} tenantMapperGoogleAds={tenantMapperGoogleAds} icp={icp} relationValue={relationValue} startdate={startdate} enddate={enddate}   />   
      </Suspense>)
    }
    return(
      <ThemeProvider theme={theme}>
            
            <Paper   className="App" >
                <Header action={action} handleChange={handleChange} handleClick={handleClick} tenantId={tenantId.current} uuid={uuid.current} segment={segment} setSegment={setSegment} setSegType={setSegType} startdate={startdate} enddate = {enddate} setstartdate = {setstartdate} setenddate = {setenddate} hideHeader={"true"} status={status} setStatus ={setStatus} icp={icp} tabValue={tabValue}  />                
                <div className="midHeading">
                <div className="topheading" >
                {/* whatever is added in the main api call please pass parameters in download button also */}

                </div>
               
                </div>
                <CustomTabs style={{ borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}  TabIndicatorProps={{ style: {
              backgroundColor: "#21DA8C",height: "6px",borderRadius: "3px"}}}
          value={tabValue}
          onChange={(e,newValue)=>handleChange(e,newValue)}
          aria-label="wrapped label tabs example"
          variant="standard">
          {resourcesAllowed.map((label)=>{
          return (
            <CustomTab style={{fontFamily:"Nunito Sans"}} label={label} ></CustomTab>
          )
        })}
          {/* <CustomTab style={{fontFamily:"Nunito Sans"}} label = "Email Campaigns"></CustomTab>
          <CustomTab  style={{fontFamily:"Nunito Sans"}} label="LinkedIn AD campaigns" ></CustomTab>
          <CustomTab  style={{fontFamily:"Nunito Sans"}} label="Google AD campaigns" ></CustomTab> */}
          {/* <CustomTab disabled style={{fontFamily:"Nunito Sans"}} label="Event campaigns"></CustomTab> */}
        </CustomTabs>
        {
          resourcesAllowed.map((label,index)=>{
            return(
              (tabValue || 0) === index && 
                 <>
                 {componentMap[label]}
                 </>
            )
          })
        }
        
        <Snackbar open={opensnack}
        autoHideDuration={6000}
        severity={snack.message}
        onClose={()=>setOpensnack(false)} >
          <Alert severity={'warning'} sx={{width:"100%"}} autoHideDuration={6000} >
            {snack.message} <br/>
            <p1 style={{fontSize:"12px"}}>
            {snack.caption}
            </p1>
          </Alert>
        </Snackbar>
            </Paper>
        </ThemeProvider>
    )
});



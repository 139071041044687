import React, { useContext } from 'react'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Fragment } from 'react';
import { filterContext } from './Filters';

export default function Header({filterName,setFilterName,checked,setChecked,handleSave,actionType,setActionType}) {
  const {attributeArr,handlingSave} = useContext(filterContext);

  // const [filterName,setFilterName] = React.useState('');
  // const [checked,setChecked] = React.useState(false);
  return (
    <div className='titlefilter'>
        <TextField
            size="small"
            id="outlined-basic" 
            placeholder="Filter Name" 
            style={{width: '33.5vw',
            height:"32px",
            borderRadius: 4,
            borderWidth: 1,}}
            value={filterName}
            onInput={(e) => {
              setFilterName(e.target.value);
            }}
            variant="outlined"
        />
        {actionType!='edit'?
         <FormControlLabel control={
        <Checkbox
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked)
        }}
        />
      }
      label="Save Filter"
      />:<Button
                  style={{
                    width: 100,
                    background: filterName===""?"#6E6E6E":"#21DA8C",
                    color: "#FFFFFF",
                    height:"32px",
                    paddingTop: "8px",
                  }}

                  disabled={filterName===""?true:false}
                  onClick={() => {
                      handlingSave(attributeArr.slice(1),filterName,actionType)
                      // applyFilters("SAVE");
                      // console.log('in edit and saving without name');
                    
                    setActionType('');
                  }}
                >
                  SAVE
                </Button>
}
         {/* <div style={{padding:"10px",width:"30vw"}}>
            {actionType === "ADD"  ? (
                <div style={{padding:"7px"}}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked)
                    }}
                  />
                  <label style={{ color: "#000000" }}>Save Filter</label>
                </div>
              ) : (
                <Button
                  style={{
                    width: 100,
                    
                    background: filterName===""?"#6E6E6E":"#21DA8C",
                    color: "#FFFFFF",
                    height:"32px",
                    paddingTop: "8px",
                    
                  }}
                  onClick={() => {
                    
                    if(filterName === ''){
                      document.getElementById("filter-name-header").focus();
                      console.log('in edit and saving without name');
                    }else{
                      applyFilters("SAVE");
                      console.log('in edit and saving without name');
                    }
                    setActionType('');
                  }}
                >
                  SAVE
                </Button>
            )}
          </div> */}
    </div>
  )
}

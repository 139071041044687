import React, { createContext, useState, useEffect } from 'react'
import { Button, ButtonGroup, FormControl, ListSubheader, MenuItem, Select, TextField, Typography, IconButton, Alert, Snackbar } from "@mui/material"
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Add, Delete, Edit, KeyboardArrowDownRounded } from '@mui/icons-material';
import Modal from './Modal';
import './filters.css';
import { nanoid } from 'nanoid';
import { deleteFilters, getFilterDataByName, getFilterNames, saveFilters } from './api';
import { toast, ToastContainer } from 'react-toastify';
export const filterContext = createContext(null);
export default function Filters(props) {
    const classes = {};
    const {tenantMapperData, tenantId, handleSave,body,handleFilter,open,setOpen,screen,startdate,enddate } = props
    console.log(",,3434,223",props)
    // const [open, setOpen] = useState(false);
    const [openSelect,setOpenSelect] = useState(false);
    const [relationObj,setRelationObj] = useState([]);
    const [attributeArr,setAttributeArr] = useState(body??[]);
    const [filterNames,setFilterNames] = useState([])
    const [selectedFilter,setSelectedFilter] = useState('')
    const [openSnackbar,setOpenSnackbar] = useState('')
    const [details,setDetails] = useState('');
    const [actionType,setActionType] = useState();
    const [filterName,setFilterName] = React.useState('');
  
    function onClearAttributes() {
        const attrList = [...attributeArr];
    
        attrList[0] = { ...attrList[0], relation: "",type:"", attribute: "", values: [] };
    
        setAttributeArr(attrList);
      }
    useEffect(()=>{
      setAttributeArr([
        {
          attrId: nanoid(),
          values: [],
          type:'',
          attribute: "",
          relation: "",
          type:"",
          isValid: false,
          index: 0,
        },
        ...body??[]
      ]);
    },[body])
    //   useEffect(()=>{
    //     if(openFilter!==open)   setOpen(openFilter)
    //   },[openFilter])
      
      const onAddFilterItem=()=> {
        if(attributeArr[0].isValid){
        setAttributeArr(obj=>([
                {
                  attrId: nanoid(),
                  values: [],
                  attribute: "",
                  type:"",
                  relation: "",
                  isValid: false,
                  index: 0,
                },
                ...obj,
              ]
        ));
            }
        // if (attributeData?.isValid) {
        //   const attrList = [...attributeList];
        //   attrList[elementIndex] = attributeData;
        //   setShowErrors(false);
        //   setAttributeList([
        //     {
        //       attrId: nanoid(),
        //       values: [],
        //       attribute: "",
        //       type:"",
        //       relation: "",
        //       isValid: false,
        //     },
        //     ...attrList,
        //   ]);
        // } else {
        //   setShowErrors(true);
        // }
      }
      const checkSave = ()=>{
        if(attributeArr.length>1) return false
        return true
      } 
      
    useEffect(() => {
        if (props.showModal === false) {
          handleClose();
        //   setFilterName("");
        }
        // setShowModal(props.showModal);
      }, [props.showModal]);
      const handleSelectClose = () => {
        setOpenSelect(false);
      };
      const getFilterBody = (filterName,mode='save')=>{
        getFilterDataByName(filterName,tenantId,screen).then(res=>{
          if(mode='save')
          handleSave(res??[])
          else 
          setAttributeArr(res??[])
        }).catch(e=>console.log(e))

      }
      const handleSelectOpen = () => {
        setOpenSelect(true);
      };
      const handleClose = () => {
        handleFilter()
        setOpenSelect(false);
      };

      const deleteFilter= (elementIndex)=>{
          console.log("inc_.",attributeArr);
          const newState = []
           attributeArr.forEach((e,index)=>{
              if(index!==elementIndex)  newState.push(e);
            })
        // const att = attributeArr.slice(elementIndex,1);
        setAttributeArr(newState);
      }
      const handlingSave = async(att,filterName,mode='save')=>{
        saveFilters(mode,att,filterName,tenantId,screen).then((json) => {
          getFilterNames(tenantId, screen).then((res) => {
              setFilterNames(res.data);
          });
          if (json?.success) {
              // setSeverity("success");
              setOpenSnackbar(true)
              setDetails({
                severity:'success',
                message:json.message??''
              })
              if (mode === "save") {
                  handleSave(att);
              } 
              // setAttributeArr()
                  setOpen(false);
              
          } else {
              // setSeverity("error");
              setDetails({
                severity:'error',
                message:json.message??''
              })
              
               setOpenSnackbar(true);
          }
          // setMessage(json?.message);
      })
  .catch((e)=>{
      console.error("savedata error",e)
  })
        
      }
      const handleOpen = (event) => {
        setOpen(true);
        // handleClose();
        setAttributeArr([
            {
              attrId: nanoid(),
              values: [],
              type:'',
              attribute: "",
              relation: "",
              type:"",
              isValid: false,
              index: 0,
            },
            ...body??[]
          ]);
          // setShowModal(true);
        //   onClickFilter("", "add");
        //   setSelectedFilter("Add Filter");
          setActionType("ADD");
          event.stopPropagation();
      };
      const handleDelete=(name)=>{
        deleteFilters(name,tenantId,screen).then(json=>{getFilterNames(tenantId, screen).then((res) => {
            setFilterNames(res.data);
          });
          if (json?.success) {
          setDetails(
            {
              severity:"success",
              message:json.message

            }
          )
          }
          else{
            setDetails({
              severity:"error",
              message:json.message
            })
          }
          setOpenSnackbar(true)
        })
      }
      const getRelationObj =  () => {
        try {
          const requestBdy = {
            tenantId: tenantId,
            tool: "b2_campaign",
            keys: ["filters_relation"],
          };
           fetch(`${window._env_.REACT_APP_TENANT_CONFIG}/tenant/get-tenant-config-json`, {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBdy),
          })
            .then((response) => response.json())
            .then((resp)=>{
               setRelationObj(resp.data.configs[0].configVal);
            })
          } catch {
            console.log("unable to fetch ");
          }
      }
    useEffect(()=>{ getRelationObj();getFilterNames(tenantId,screen).then(result=>setFilterNames(result.data))},[])

  return (
    <filterContext.Provider
    value={{tenantId,tenantMapperData, relationObj,attributeArr,onClearAttributes,onAddFilterItem,deleteFilter,checkSave,handleSave,handlingSave,screen,enddate,startdate}}
    >
        <FormControl size="small" variant="outlined"  style={{outlineColor:"#21DA8C",color:"#8A8A8A"}}> 
            <Select
            onClose={handleSelectClose}
            onOpen={handleSelectOpen}
            IconComponent={KeyboardArrowDownRounded}
            open={openSelect}
            labelId="filter-label"
            id="filter-setup"
            defaultValue="Filters"
            //   value={selectedFilter}
            style={{borderRadius:"95px", height: '36px',fontFamily:"Nunito Sans",width: "150px"}}
            startAdornment={
                <div style={{marginRight:'2px'}}>
                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.29785 15C7.29785 15.5523 7.74557 16 8.29785 16H10.2979C10.8501 16 11.2979 15.5523 11.2979 15V13.3077C11.2979 12.7554 10.8501 12.3077 10.2979 12.3077H8.29785C7.74557 12.3077 7.29785 12.7554 7.29785 13.3077V15ZM1.29785 0C0.745567 0 0.297852 0.447715 0.297852 1V2.69231C0.297852 3.24459 0.745567 3.69231 1.29785 3.69231H17.2979C17.8501 3.69231 18.2979 3.24459 18.2979 2.69231V1C18.2979 0.447716 17.8501 0 17.2979 0H1.29785ZM3.29785 8.84615C3.29785 9.39844 3.74557 9.84615 4.29785 9.84615H14.2979C14.8501 9.84615 15.2979 9.39844 15.2979 8.84615V7.15385C15.2979 6.60156 14.8501 6.15385 14.2979 6.15385H4.29785C3.74557 6.15385 3.29785 6.60156 3.29785 7.15385V8.84615Z" fill="#686868"/>
                </svg>
                </div>
            }
            displayEmpty={true}
            className={classes.select}
            renderValue={(value) =>{
                if(value.length==0) return <div style={{alignItems: 'center',marginLeft:'2px',paddingBottom:'3px',color:'#21DA8C'}}>Filter</div>
                return <div style={{marginLeft:'2px',paddingBottom:'3px',fontFamily:"Nunito Sans"}}>{value}</div>}}
            inputProps={{
                classes: {
                    icon: classes.icon,
                    root: classes.root,
                },
            }}
            // renderValue={(value) => <label>{value}</label>}
            // label="Filter"
            MenuProps={
                        
                {
                PaperProps: {
                    style: {
                    borderRadius: "16px"
                    }
                },
                getContentAnchorEl: () => null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
    
                    }
                    
                }
            >
            <MenuItem
                margin="dense"
                value="New Filter"
                style={{
                display: "flex",
                alignItems: "center",
                width:'inherit',
                height: "36px",
                justifyContent: "center",
                background: "#fff",
                padding: "0 8px",
                color:"rgba(0, 0, 0, 0.87)",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontSize: "16px",
                fontFamily:"Nunito Sans"
                }}
                onClick={(event) => {
                    handleOpen(event);
                }}
            >
                <Add style={{color:"rgba(0, 0, 0, 0.87)"}}/> New Filter
            </MenuItem>
            {filterNames?.map((option, index) => (
                <MenuItem style={{ padding: 0, width: "inherit", }} key={index}>
                <div
                    style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "36px",
                    background: "#fff",
                    padding: "0 8px",
                    color:"rgba(0, 0, 0, 0.87)",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    fontSize: "16px",
                    }}
                    
                >
                    <span
                    style={{
                        margin: 0,
                        // marginLeft: 15,
                        marginLeft:'5px', 
                        width: 100,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedFilter(option?.name || option?.filterName);
                      getFilterBody(option?.name || option?.filterName);
                      handleSelectClose()
                      
                    //   typoLabel === 'Account Hub' ? setAccountFilterName(option?.filterName) : setContactFilterName(option?.filterName);
                    //   onClickFilter(option?.name||option?.filterName, "item");
                    }}
                    >
                    {option?.name ||option?.filterName}
                    </span>
                    <div>
                    <IconButton aria-label="edit" style={{ padding: "0",marginRight: "5px" }}>
                        <Edit
                        onClick={(event) => {
                            getFilterBody(option?.name ||option?.filterName,'edit');
                            setSelectedFilter(option?.name ||option?.filterName);
                            setFilterName(option?.name ||option?.filterName);
                            setOpen(true)
                            setActionType("edit");
                            event.stopPropagation();
                        }}
                        style={{ color: "#939393",  }}
                        />
                    </IconButton>
                    <IconButton aria-label="edit" style={{ padding: "0",marginRight: "5px" }}>
                        <Delete
                        onClick={(event) => {
                            // onClickFilter(option?.name,'delete');
                            // if(selectedFilter === option?.name || selectedFilter === option?.filterName){
                              setSelectedFilter('');
                              handleDelete(option?.name ||option?.filterName);
                            // }
                            // setOpenConfirmation(true);
                            // setDeletedFilter(option?.name ||option?.filterName);
                            // event.stopPropagation();
                        }}
                        style={{ color: "#939393", }}
                        />
                    </IconButton>
                    </div>
                </div>
                </MenuItem>
            ))}
            
            </Select>
        </FormControl>
        <Modal open={open} handleClose={handleClose} handleOpen={handleOpen} filterName={filterName} setFilterName={setFilterName} actionType={actionType} setActionType={setActionType}/>
        <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={()=>{setOpenSnackbar(false)}}
>
<Alert onClose={()=>{setOpenSnackbar(false)}} severity={details.success}>{details.message}</Alert>
</Snackbar>
    </filterContext.Provider>
  )
}

import React from "react";
import "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./DateRangePickerComponent.css";
import "date-fns";
import Button from "@material-ui/core/Button";
import Popover from "@mui/material/Popover";
import { Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UItheme from "@bamboobox/b2-theme";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { useEffect } from "react";

function convertDateRangeToFormattedString(oldDate, currentDate) {
  let monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  console.log("olddate---->",oldDate);
  if(oldDate === "")
    oldDate =new Date();
  var tempOldDate = new Date(oldDate);
  ;
  // tempOldDate.setUTCDate(tempOldDate.getDate());
  // tempOldDate.setUTCHours(0, 0, 0, 0);
  var tempCurrentDate = new Date(currentDate);
  // tempCurrentDate.setUTCDate(tempCurrentDate.getDate());
  // tempCurrentDate.setUTCHours(0, 0, 0, 0);
  let dateString = `${tempOldDate.getDate()} ${
    monthArray[tempOldDate.getMonth()]
  } ${tempOldDate
    .getFullYear()
    .toString()
    .substring(2, 4)} - ${tempCurrentDate.getDate()} ${
    monthArray[tempCurrentDate.getMonth()]
  } ${tempCurrentDate.getFullYear().toString().substring(2, 4)}`;
  console.log(
    "convertDateRangeToFormattedString",
    tempOldDate,
    tempCurrentDate,
    dateString
  );
  if (tempOldDate == "Invalid Date" || tempCurrentDate == "Invalid Date")
    return "Date Range";
    
  return dateString;
}
format(new Date(), "Pp"); // "06/29/2021, 11:35 AM"
const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0px !important",
  },
}));

function DateRangePickerComponent(props) {

 
  const [dateobj,setdateobj] = React.useState("91");
   const getRelationObj =  () => {
    try {
      const requestBdy = {
        tenantId: props.tenantId,
        tool: "campaign-hub",
        keys: ["date-difference"],
      };
      fetch(`${window._env_.REACT_APP_TENANT_CONFIG}/tenant/get-tenant-config`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBdy),
      }).then((response) => response.json())
      .then((resp)=>{
          console.log("resp[0]?.configVal-->",resp[0]?.configVal)
          setdateobj(resp[0]?.configVal)
          resp[0]?.configVal && setOldDate(getOldDate(resp[0]?.configVal));
          props.setFromDate(new Date(getOldDate(resp[0]?.configVal)));
      })
      } catch {
        console.log("unable to fetch ");
      }
  }
  console.log("tenantId",props.tenantId);

  useEffect(()=>{ 
    getRelationObj();
    // try {
    //   const requestBdy = {
    //     tenantId: props.tenantId,
    //     tool: "campaign-hub",
    //     keys: ["date-difference"],
    //   };
    //    fetch(`${window._env_.REACT_APP_TENANT_CONFIG}/${props.tenantId}/get-tenant-config`, {
    //     credentials: "include",
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(requestBdy),
    //   })
    //     .then((response) => response.json())
    //     .then((resp)=>{
    //       console.log("resp.data.configs[0].configVal--",resp.data.configs[0].configVal);
    //       setdateobj(resp.data.configs[0].configVal);
    //     })
    //   } catch {
    //     console.log("unable to fetch ");
    //   }
  },[])

  const frequencyValues = ["Weeks", "Months", "Quarters", "Years"];
  const classes = useStyles();
  
  const getOldDate = (dateobj) => {
    let date = new Date();
    return date.setDate(date.getDate() - dateobj);;
  };
  const [open, setOpen] = React.useState(true);

  const [currentDate, setCurrentDate] = React.useState(
    sessionStorage.getItem("toDate")? sessionStorage.getItem("toDate") :( props?.enddate ? new Date(props?.enddate) : new Date())
  );
  const [oldDate, setOldDate] = React.useState(
   sessionStorage.getItem("fromDate")? sessionStorage.getItem("fromDate") :(props?.startdate ? new Date(props?.startdate) : getOldDate(dateobj))
  );

  const [displayedDate, setDisplayedDate] = React.useState(
    convertDateRangeToFormattedString(
      sessionStorage.getItem("fromDate"),
      sessionStorage.getItem("toDate")
    ) || convertDateRangeToFormattedString(oldDate, currentDate)
  );
  const [fromDateMax, setFromDateMax] = React.useState(new Date());
  const [fromDateMin, setFromDateMin] = React.useState();
  const [toDateMax, setToDateMax] = React.useState(new Date());
  const [toDateMin, setToDateMin] = React.useState();

  const [radioBtnValue, setRadioBtnValue] = React.useState("fixed");
  const [disable, setdisable] = React.useState(false);
  const [numberFieldvalue, setNumberFieldvalue] = React.useState("3");
  const [intervalValue, setIntervalValue] = React.useState("Weeks");
  const [prevOldDate, setPrevOldDate] = React.useState(new Date(oldDate));
  const [prevCurrentDate, setPrevCurrentDate] = React.useState(
    new Date(currentDate)
  );
  const [prevIntervalValue, setPrevIntervalValue] =
    React.useState(intervalValue);
  
  const [prevNumberFieldValue, setPrevNumberFieldValue] =
    React.useState(numberFieldvalue);
  const [flag, setFlag] = React.useState(0);

  // let setFromDate = props?.setFromDate;
  // let setToDate = props?.setToDate;
  React.useEffect(() => {
    if (sessionStorage.getItem("fromDate") === null || sessionStorage.getItem("fromDate") === '') {
      setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
    }
    if (flag == 0 && currentDate && oldDate) {
      var tempOldDate = new Date(oldDate);
      tempOldDate.setUTCDate(tempOldDate.getDate());
      tempOldDate.setUTCHours(0, 0, 0, 0);
      var tempCurrentDate = new Date(currentDate);
      tempCurrentDate.setUTCDate(tempCurrentDate.getDate());
      tempCurrentDate.setUTCHours(0, 0, 0, 0);
      // props.onAttributeChange([tempOldDate, tempCurrentDate], "values");
      setFlag(1);
    }
  });
  useEffect(()=>{
    ;
    setTimeout(() => {
      
      if(sessionStorage.getItem("toDate"))  setCurrentDate(sessionStorage.getItem('toDate'))
      if(sessionStorage.getItem('fromDate')) setOldDate(sessionStorage.getItem('fromDate'));
    }, 1000);
  },[sessionStorage.getItem('fromDate'),sessionStorage.getItem("toDate")])
  const handleRadioBtnChange = (event) => {
    console.log("changed radio button to ", event?.target?.value);
    setRadioBtnValue(event?.target?.value);
    let e = { target: { value: "" } };
    e.target.value = intervalValue;
    // handleIntervalChange(e);
  };





  useEffect(() => {
    if (props.screen === "2") {
      setdisable(true);
      console.log("ttt--", sessionStorage.getItem("fromDate"));
      if (sessionStorage.getItem("fromDate") === null || sessionStorage.getItem("fromDate") === '') {
        setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
      } else
        setDisplayedDate(
          convertDateRangeToFormattedString(
            sessionStorage.getItem("fromDate"),
            sessionStorage.getItem("toDate")
          )
        );
    }
    console.log("screen2val--->", props.screen);
  });
  const handleNormalBtnClick = (event) => {
    setOpen(true);
    if (open) {
      setAnchorEl(event.currentTarget);
      // setDisplayedDate('Date Range')
      //   setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
      // var fromDateValue = new Date(oldDate)
      // fromDateValue.setUTCDate(fromDateValue.getDate())
      // fromDateValue.setUTCHours(0, 0, 0, 0)

      // var toDateValue = new Date(currentDate)
      // toDateValue.setUTCDate(toDateValue.getDate())
      // toDateValue.setUTCHours(0, 0, 0, 0)
      // console.log("setFromDate --->",props.setFromDate)
      // setOpen(false);
    }
  };

  const handleDateFromChange = (value) => {
    if(value){
      let tempDate = value;
      // tempDate.setUTCDate(tempDate.getDate());
      // tempDate.setUTCHours(0, 0, 0, 0);
      setToDateMin(tempDate.setDate(tempDate.getDate()));
      setPrevOldDate(oldDate);
      setOldDate(new Date(value));
      let d1 = new Date(value);
      var tempCurrentDate = new Date(currentDate);
      tempCurrentDate.setUTCDate(tempCurrentDate.getDate());
      tempCurrentDate.setUTCHours(0, 0, 0, 0);
      // props.onAttributeChange([tempDate, tempCurrentDate], "values");
      // props.setstartdate([tempDate, tempCurrentDate], "values");
  
      console.log(
        "handleDateFromChange ",
        value,
        new Date(value),
        tempDate,
        toDateMin
      );
    }
  };

  const handleDateToChange = (value) => {
    if(value){
      console.log("date pick",value)
      setCurrentDate(new Date(value));
      var toDateValue = value;
      // toDateValue.setUTCDate(toDateValue.getDate());
      // toDateValue.setUTCHours(0, 0, 0, 0);
      setFromDateMax(toDateValue.setDate(toDateValue.getDate()));
      var tempOldDate = new Date(oldDate);
      tempOldDate.setUTCDate(tempOldDate.getDate());
      tempOldDate.setUTCHours(0, 0, 0, 0);
      let d1 = new Date(value);
      // props.onAttributeChange([tempOldDate, toDateValue], "values")
      // props.setenddate = toDateValue
      console.log(
        "handleDateToChange ",
        value,
        new Date(value),
        toDateValue,
        fromDateMin
      );
    }
  };

  const handleClickAway = () => {
    console.log(
      "handleClickAway",
      convertDateRangeToFormattedString(oldDate, currentDate)
    );
    setOpen(false);
    setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
    var tempOldDate = new Date(oldDate);

    // tempOldDate.setUTCDate(tempOldDate.getDate());
    // tempOldDate.setUTCHours(0, 0, 0, 0);
    var fromDateValue = new Date(
      Date.UTC(
        tempOldDate.getFullYear(),
        tempOldDate.getMonth(),
        tempOldDate.getDate()
      )
    );
    // fromDateValue.setUTCDate(fromDateValue.getDate())
    // fromDateValue.setUTCHours(0, 0, 0, 0)
    sessionStorage.removeItem("fromDate");
    sessionStorage.removeItem("toDate");
    var tempCurrentDate = new Date(currentDate);
    // tempCurrentDate.setUTCDate(tempCurrentDate.getDate());
    // tempCurrentDate.setUTCHours(0, 0, 0, 0);
    var toDateValue = new Date(
      Date.UTC(
        tempCurrentDate.getFullYear(),
        tempCurrentDate.getMonth(),
        tempCurrentDate.getDate()
      )
    );
    
    // toDateValue.setUTCDate(toDateValue.getDate())
    // toDateValue.setUTCHours(0, 0, 0, 0)
    props.setFromDate(fromDateValue);
    props.setToDate(toDateValue);
    // if(sessionStorage.getItem("fromDate") !== null || sessionStorage.getItem("fromDate") !== ''){
    //   setOldDate(convertDateRangeToFormattedString(sessionStorage.getItem("fromDate")));
    // }
    
    // if(sessionStorage.getItem("toDate") !== null || sessionStorage.getItem("toDate") !== ''){
    //   setCurrentDate(convertDateRangeToFormattedString(sessionStorage.getItem("toDate")));
    // }
    sessionStorage.setItem("fromDate", fromDateValue);
    sessionStorage.setItem("toDate", toDateValue);
  };

  const handleCancelBtn = () => {
    setOpen(false);
    ;
    
    // console.log(prevOldDate);
    const resetDate = new Date();
    resetDate.setDate(resetDate.getDate() - dateobj);;
    setOldDate(resetDate);
    // setCurrentDate(prevCurrentDate);
    setCurrentDate(new Date())
    setNumberFieldvalue(prevNumberFieldValue);
    setIntervalValue(prevIntervalValue);
    props.setFromDate("");
    props.setToDate("");
    setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
    sessionStorage.setItem("fromDate", "");
    sessionStorage.setItem("toDate", "");
  };
  const [anchorEl, setAnchorEl] = React.useState(false);
  const popupOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <MuiThemeProvider theme={UItheme}>
      <ClickAwayListener onClickAway={() => {}}>
        <span>
          <Button
            disabled={disable}
            onClick={handleNormalBtnClick}
            className="DateButton"
            variant="outlined"
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#8A8A8A",
              borderColor: "rgb(180 169 169)",
              height: "33px",
              width: "180px",
              fontSize: "16px",
              textTransform: "none",
              radius: "4px",
              padding: "8px",
            }}
          >
            <Tooltip title={displayedDate}>
              <Typography width={180} noWrap margin={0} fontSize={14}>
                {displayedDate}
              </Typography>
            </Tooltip>
            <DateRangeRoundedIcon style={{ fill: "rgba(3, 3, 3, 0.6)" }} />
          </Button>

          {open ? (
            <Popover
              className="date-picker"
              style={{ borderRadius: "28px", width: "400pX", top: "35px", display:"flex"}}
              open={popupOpen}
              onClose={handleClose}
              anchorEl={anchorEl}
            >
            <div>
              <div style={{height:"56px",marginBottom:"12px"}}>
              <Typography style={
                {
                  color:"var(--text-primary, rgba(0, 0, 0, 0.87))",
                  fontFamily:"Nunito Sans",
                  "fontSize":"14px",
                  fontStyle:"normal",
                  fontWeight:"600",
                  lineHeight:"143%",
                  marginBottom:"12px"
                }
              }>
              Select campaign date
              </Typography>
              <Typography style={
                {
                  color:"#ADADAD",
                  fontFamily:"Nunito Sans",
                  "fontSize":"12px",
                  fontStyle:"normal",
                  fontWeight:"400",
                  lineHeight:"normal"
                }
              }>
              Filter results based on campaign start and end dates.
              </Typography>
              </div>
              <div style={{display:"flex",marginBottom:"12px"}}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                style={{ padding: "8px" }}
              >
                <div style={{ padding: "4px" }}>
                  From:
                  <br></br>
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={fromDateMin}
                    maxDate={fromDateMax}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    InputProps={{
                      style: {
                        height: 30,
                        color: "rgba(3, 3, 3, 0.6)",
                        borderStyle: "solid",
                        borderWidth: 1,
                        
                        // alignSelf:"center"
                      },
                    }}
                    // style={{ width: "18vw" }}
                    value={oldDate}
                    autoOk={true}
                    onChange={handleDateFromChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
                <div style={{ padding: "4px" }}>
                  To:
                  <br></br>
                  <KeyboardDatePicker
                    disableToolbar
                    disabled={props?.disabled || false}
                    minDate={toDateMin}
                    maxDate={toDateMax}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    InputProps={{
                      style: {
                        height: 30,
                        color: "rgba(3, 3, 3, 0.6)",
                        borderStyle: "solid",
                        borderWidth: 1,
                        
                        // alignSelf:"center"
                        
                      },
                    }}
                    // style={{ width: "18vw" }}
                    value={currentDate}
                    autoOk={true}
                    onChange={handleDateToChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCancelBtn}
                  style={{
                    marginRight: "8px",
                    height: "30px",
                    width: "fit-content",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickAway}
                  style={{ height: "30px", width: "fit-content" }}
                >
                  Apply
                </Button>
              </div>
            </div>
              
            </Popover>
          ) : null}
        </span>
      </ClickAwayListener>
    </MuiThemeProvider>
  );
}

export default DateRangePickerComponent;

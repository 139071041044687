
import { TextField,InputAdornment } from '@mui/material';

 const SearchBar=(props)=>{
 try {
    const handleInput=(ele)=>{
        const input = ele.target.value;
        props.setSearchInput(input);
    }
    return(
        <TextField
            // onChange={getDataFromAPI}
            onInput={handleInput}
            variant="outlined"
            InputProps={{
              endAdornment:(
                <InputAdornment position="end">
                  {/* <Search/> */}
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8924 15.4209C6.54476 15.4209 3.02026 11.9696 3.02026 7.71204C3.02026 3.45451 6.54472 0.00317383 10.8924 0.00317383C15.2401 0.00317383 18.7646 3.45455 18.7646 7.71208C18.7646 11.9696 15.2401 15.4209 10.8924 15.4209ZM10.8924 2.20573C7.78693 2.20573 5.26946 4.67099 5.26946 7.71208C5.26946 10.7532 7.78693 13.2184 10.8924 13.2184C13.9979 13.2184 16.5154 10.7532 16.5154 7.71208C16.5154 4.67099 13.9979 2.20573 10.8924 2.20573Z" fill="#595959"/>
                  <path d="M1.89576 17.6234C1.59683 17.6251 1.30953 17.5102 1.09731 17.304C0.656314 16.8757 0.653406 16.1784 1.0908 15.7466C1.09297 15.7445 1.09514 15.7423 1.09731 15.7402L5.32577 11.5994C5.78227 11.1676 6.50983 11.1799 6.95082 11.627C7.39181 12.074 7.37922 12.7865 6.92272 13.2183L2.69422 17.304C2.482 17.5102 2.1947 17.6251 1.89576 17.6234Z" fill="#595959"/>
                  </svg>
                </InputAdornment>
              ),
            placeholder: "Search for Campaign",
            style:{
              fontStyle: "italic",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "14px",
              /* identical to box height */
              color: "#B7B7B7",
              borderRadius: "16px",
              height:"32px",
              boxSizing:"border-box",
              margin:"24px 0px",
            }

            }}

          />
    )
 } catch (error) {
    console.error("Error occurred in SearchBar:",error);
 }   
}
export default SearchBar;